export const memberPath = {
  home: "/",
  signIn: "/sign-in",
  signUp: "/sign-up",
  password: "/password",
  profileSetup: "/profile-setup",
  emailVerified: "/email-verified",
  personalProfile: "/personal-profile",
  businessDetail: "/business-detail",
  emailVerification: "/email-verification",
  subscription: "/subscription",
  choosePassword: "/choose-password",
  resetPassword: "/reset-password",
  pinSetup: "/pin-setup",
  currentPassword: "/current-password",
  createNewPassword: "/create-new-password",
  emailSent: "/email-sent",
  passwordSuccess: "/password-success",
  forgetPassword: "/forget-password",
  newPassword: "/new-password",
  forgetPin: "/forget-pin",
  checkEmail: "/check-email",
  currentPin: "/current-pin",
  newPin: "/new-pin",
  myProfile: "/my-profile",
  changeEmail: "/change-email",
  emailSuccess: "/verify-email-change",
  profileEmailChange: "/profile-email-change",
  addCard: "/add-card",
  memberSubscription: "/member-subscription",
  createOfferServices: "/create-offer",
  myOffers: "/my-offers",
  myOffer: "/my-offer/:id",
  membership: "/membership",
  cancelMembership: "/cancel-membership",
  offer: "/offers",
  reviewOffer: "/review-offer",
  reviewSubmit: "/review-submit",
  generalLocation: "/general-location",
  editDraft: "/edit-offer/:id",
  inviteFriend: "/invite-a-friend",
  notification: "/notification",
  loadBits: "/my-wallet/load-bits",
  bitsInfo: "/my-wallet/bits-info",
  bitsCardDetails: "/my-wallet/load-bits/card-details",
  bitsPaymentInfo: "/my-wallet/load-bits/bits-payment-info",
  myFavourite: "/my-favourite",
  linkExpire: "/link-expire",
  changeEmailLinkExpire: "/change-email-link-expire",
  forgotPinLinkExpire: "/forgot-pin-link-expire",
  forgotPasswordLinkExpire: "/forgot-password-link-expire",
  myWallet: "/my-wallet",
  transactionDetails: "/my-wallet/:id",
  currentEmailChange: "/current-email-change",
  manageCard: "/manage-card",
  createCard: "/card/:type",
  searchProduct: "/search-product",
  donate: "/donate",
  barterRecord: "/my-wallet/barter-record",
  processBarter: "/my-wallet/process-barter",
  barterRequests: "/my-wallet/barter-requests",
  transactionRequest: "/my-wallet/barter-requests/:id",
  statement: "/my-wallet/statement",
  transactionSuccessful: "/my-wallet/barter-requests/transaction-successful",
  findMembers: "/find-members",
  memberProfile: "/member-profile/:id",
  pin: "/pin",
  reportOffer: "/report-offer",
  acceptBits: "/accept-bits",
  bitsGift: "/bits-gift/:id",
  successAcceptGifts: "/success-accept-gits",
  sentBitsGift: "/send-bits-gift",
  userInfo: "/sent-gifts",
  giftDetail: "/gift-detail/:id",
  editCalender: "/edit-calender",
  chat: "/chat",
  feedback: "/feedback",
  reportIssue: "/report-issue",
  reportProblem: "/report-problem",
  feedbackSuccess: "/submit-successfully",
  reviewRating: "/rating-review",
  reportReview: "/rating-review/:id/report-review",
  editReview: "/rating-review/:id/edit-review",
  replyReview: "/rating-review/:id/reply-review",
  editReviewReply: "/rating-review/:id/edit-reply",
  memberReview: "/member-profile/:id/member-review",
  postReview: "/member-profile/:id/post-review",
  exploreBusiness: "/explore-business",
  restrictions: "/web-restrictions",
  businessCategories: "/business-categories",
  confirmCategories: "/confirm-categories",
  confirmSubmission: "/confirm-submission",
  editPersonalInfo: "/my-profile/edit-personal-info",
  editBusinessDetails: "/my-profile/edit-business-details",
  editCategories: "/my-profile/edit-categories",
  editSocials: "/my-profile/edit-socials",
};

export const memberProfileLayoutData: Record<
  string,
  { text?: string; disableNavBack?: boolean; showTitleWrapper?: boolean }
> = {
  [memberPath.profileSetup]: {
    text: "Yay, you are back. Now let’s choose your account type.",
    showTitleWrapper: true,
    disableNavBack: true,
  },
  [memberPath.personalProfile]: {
    text: "Great, let's create your account.",
    disableNavBack: false,
    showTitleWrapper: true,
  },
  [memberPath.businessDetail]: {
    text: "Tell us about your business.",
    disableNavBack: false,
    showTitleWrapper: true,
  },
  [memberPath.pinSetup]: {
    text: "Welcome to the Barter It<sup>TM<sup> family!",
    disableNavBack: true,
    showTitleWrapper: true,
  },
  [memberPath.subscription]: {
    text: "Join your new barter family.",
    disableNavBack: false,
    showTitleWrapper: true,
  },
  [memberPath.emailSent]: {
    disableNavBack: true,
    showTitleWrapper: false,
  },
  [memberPath.restrictions]: {
    disableNavBack: false,
    showTitleWrapper: false,
  },
  [memberPath.passwordSuccess]: {
    disableNavBack: true,
    showTitleWrapper: false,
  },
  [memberPath.forgetPassword]: {
    disableNavBack: true,
    showTitleWrapper: false,
  },
  [memberPath.newPassword]: {
    disableNavBack: true,
    showTitleWrapper: false,
  },
  [memberPath.choosePassword]: {
    text: "Choose a password.",
    disableNavBack: true,
    showTitleWrapper: true,
  },
  [memberPath.emailVerified]: {
    text: "Choose a password.",
    disableNavBack: true,
    showTitleWrapper: false,
  },
  [memberPath.addCard]: {
    disableNavBack: true,
    showTitleWrapper: false,
  },
  [memberPath.memberSubscription]: {
    disableNavBack: true,
    showTitleWrapper: false,
  },
  [memberPath.generalLocation]: {
    text: "Tell us where you’re generally located.",
    disableNavBack: false,
    showTitleWrapper: true,
  },

  [memberPath.linkExpire]: {
    disableNavBack: true,
    showTitleWrapper: false,
  },
  [memberPath.forgotPinLinkExpire]: {
    disableNavBack: true,
    showTitleWrapper: false,
  },
  [memberPath.forgotPasswordLinkExpire]: {
    disableNavBack: true,
    showTitleWrapper: false,
  },
  [memberPath.changeEmailLinkExpire]: {
    disableNavBack: true,
    showTitleWrapper: false,
  },
  [memberPath.businessCategories]: {
    text: "Tell us about your business.",
    disableNavBack: true,
    showTitleWrapper: true,
  },
  [memberPath.confirmCategories]: {
    text: "Tell us about your business.",
    disableNavBack: false,
    showTitleWrapper: true,
  },
  [memberPath.confirmSubmission]: {
    text: "Tell us about your business.",
    disableNavBack: false,
    showTitleWrapper: true,
  },
};

export const defaultProfileLayoutData: Record<
  string,
  {
    title?: string;
    showBannerV1?: boolean;
    showBannerV2?: boolean;
    showButton?: boolean;
    showHeaderNavbar?: boolean;
    buttonText?: string;
    showAddressSearch?: boolean;
    redirectUrl?: string;
    bannerSrc?: string;
    greyBannerStyles?: any;
    text?: string;
    isTradmarkText?: boolean;
  }
> = {
  [memberPath.createOfferServices]: {
    title: "",
    showHeaderNavbar: false,
    showBannerV1: false,
    showBannerV2: false,
    showButton: false,
    buttonText: "",
    showAddressSearch: false,
  },
  [memberPath.donate]: {
    title: "",
    showHeaderNavbar: true,
    showBannerV1: false,
    showBannerV2: false,
    showButton: false,
    buttonText: "",
    showAddressSearch: true,
  },
  [memberPath.bitsInfo]: {
    title: "Load BITS™",
    showHeaderNavbar: true,
    showBannerV1: true,
    showBannerV2: false,
    showButton: true,
    buttonText: "Load BITS™",
    showAddressSearch: true,
    redirectUrl: memberPath.loadBits,
  },
  [memberPath.loadBits]: {
    title: "",
    showHeaderNavbar: true,
    showBannerV1: false,
    showBannerV2: false,
    showButton: false,
    buttonText: "",
    showAddressSearch: true,
    redirectUrl: "memberPath.loadBits",
  },
  [memberPath.bitsCardDetails]: {
    title: "",
    showHeaderNavbar: true,
    showBannerV1: false,
    showBannerV2: false,
    showButton: false,
    buttonText: "",
    showAddressSearch: true,
    redirectUrl: "memberPath.loadBits",
  },
  [memberPath.bitsPaymentInfo]: {
    title: "",
    showHeaderNavbar: true,
    showBannerV1: false,
    showBannerV2: false,
    showButton: false,
    buttonText: "",
    showAddressSearch: true,
    redirectUrl: "memberPath.loadBits",
  },
  [memberPath.myWallet]: {
    title: "",
    showHeaderNavbar: true,
    showBannerV1: false,
    showBannerV2: true,
    showButton: false,
    buttonText: "",
    showAddressSearch: true,
    redirectUrl: "memberPath.loadBits",
  },
  [memberPath.transactionDetails]: {
    title: "",
    showHeaderNavbar: true,
    showBannerV1: false,
    showBannerV2: false,
    showButton: false,
    buttonText: "",
    showAddressSearch: true,
    redirectUrl: "",
  },
  [memberPath.transactionRequest]: {
    title: "",
    showHeaderNavbar: true,
    showBannerV1: false,
    showBannerV2: false,
    showButton: false,
    buttonText: "",
    showAddressSearch: true,
    redirectUrl: "",
  },
  [memberPath.myProfile]: {
    title: "",
    showHeaderNavbar: true,
    showBannerV1: false,
    showBannerV2: false,
    showButton: false,
    buttonText: "",
    showAddressSearch: true,
    redirectUrl: "",
  },
  [memberPath.editPersonalInfo]: {
    title: "",
    showHeaderNavbar: true,
    showBannerV1: false,
    showBannerV2: false,
    showButton: false,
    buttonText: "",
    showAddressSearch: true,
    redirectUrl: "",
  },
  [memberPath.editBusinessDetails]: {
    title: "",
    showHeaderNavbar: true,
    showBannerV1: false,
    showBannerV2: false,
    showButton: false,
    buttonText: "",
    showAddressSearch: true,
    redirectUrl: "",
  },
  [memberPath.editCategories]: {
    title: "",
    showHeaderNavbar: true,
    showBannerV1: false,
    showBannerV2: false,
    showButton: false,
    buttonText: "",
    showAddressSearch: true,
    redirectUrl: "",
  },
  [memberPath.editSocials]: {
    title: "",
    showHeaderNavbar: true,
    showBannerV1: false,
    showBannerV2: false,
    showButton: false,
    buttonText: "",
    showAddressSearch: true,
    redirectUrl: "",
  },

  [memberPath.myOffers]: {
    title: "",
    showHeaderNavbar: true,
    showBannerV1: false,
    showBannerV2: false,
    showButton: false,
    buttonText: "",
    showAddressSearch: true,
    redirectUrl: "",
  },
  [memberPath.myOffer]: {
    title: "",
    showHeaderNavbar: true,
    showBannerV1: false,
    showBannerV2: false,
    showButton: false,
    buttonText: "",
    showAddressSearch: true,
    redirectUrl: "",
  },
  [memberPath.myFavourite]: {
    title: "",
    showHeaderNavbar: true,
    showBannerV1: false,
    showBannerV2: false,
    showButton: false,
    buttonText: "",
    showAddressSearch: true,
    redirectUrl: "",
  },
  [memberPath.currentPassword]: {
    title: "",
    showHeaderNavbar: true,
    showBannerV1: false,
    showBannerV2: false,
    showButton: false,
    buttonText: "",
    showAddressSearch: true,
    redirectUrl: "",
  },
  [memberPath.currentPin]: {
    title: "",
    showHeaderNavbar: true,
    showBannerV1: false,
    showBannerV2: false,
    showButton: false,
    buttonText: "",
    showAddressSearch: true,
    redirectUrl: "",
  },
  [memberPath.newPin]: {
    title: "",
    showHeaderNavbar: true,
    showBannerV1: false,
    showBannerV2: false,
    showButton: false,
    buttonText: "",
    showAddressSearch: true,
    redirectUrl: "",
  },
  [memberPath.manageCard]: {
    title: "",
    showHeaderNavbar: true,
    showBannerV1: false,
    showBannerV2: false,
    showButton: false,
    buttonText: "",
    showAddressSearch: true,
    redirectUrl: "",
  },
  [memberPath.createCard]: {
    title: "",
    showHeaderNavbar: true,
    showBannerV1: false,
    showBannerV2: false,
    showButton: false,
    buttonText: "",
    showAddressSearch: true,
    redirectUrl: "",
  },
  [memberPath.notification]: {
    title: "",
    showHeaderNavbar: true,
    showBannerV1: false,
    showBannerV2: false,
    showButton: false,
    buttonText: "",
    showAddressSearch: true,
    redirectUrl: "",
  },
  [memberPath.home]: {
    title: "",
    showHeaderNavbar: true,
    showBannerV1: false,
    showBannerV2: false,
    showButton: false,
    buttonText: "",
    showAddressSearch: true,
    redirectUrl: "",
  },
  [memberPath.forgetPin]: {
    title: "",
    showHeaderNavbar: true,
    showBannerV1: false,
    showBannerV2: false,
    showButton: false,
    buttonText: "",
    showAddressSearch: true,
    redirectUrl: "",
  },
  [memberPath.checkEmail]: {
    title: "",
    showHeaderNavbar: true,
    showBannerV1: false,
    showBannerV2: false,
    showButton: false,
    buttonText: "",
    showAddressSearch: true,
    redirectUrl: "",
  },
  [memberPath.inviteFriend]: {
    title: "",
    isTradmarkText: true,
    text: "Earn 25 BITS<sup>TM<sup> when you invite a friend.",
    showHeaderNavbar: true,
    showBannerV1: true,
    showBannerV2: false,
    showButton: false,
    buttonText: "",
    showAddressSearch: true,
    redirectUrl: "",
    bannerSrc:
      "/assets/images/inviteFriend.png 300w, /assets/images/process-barter-banner.png 600w, /assets/images/inviteFriend.png 1200w",
  },
  [memberPath.barterRecord]: {
    title: "Barter Sales",
    showHeaderNavbar: true,
    showBannerV1: true,
    showBannerV2: false,
    showButton: true,
    buttonText: "Process Sale",
    showAddressSearch: true,
    redirectUrl: memberPath.processBarter,
    bannerSrc: "/assets/images/process-barter-banner.png",
  },
  [memberPath.processBarter]: {
    title: "",
    showHeaderNavbar: true,
    showBannerV1: false,
    showBannerV2: false,
    showButton: false,
    buttonText: "",
    showAddressSearch: true,
  },
  [memberPath.transactionSuccessful]: {
    title: "",
    showHeaderNavbar: true,
    showBannerV1: false,
    showBannerV2: false,
    showButton: false,
    buttonText: "",
    showAddressSearch: true,
  },
  [memberPath.searchProduct]: {
    title: "",
    showHeaderNavbar: true,
    showBannerV1: true,
    showBannerV2: false,
    showButton: false,
    buttonText: "",
    showAddressSearch: true,
    bannerSrc: "/assets/images/searchProductBackground.png",
  },
  [memberPath.barterRequests]: {
    title: "Purchases Made Using BITS™",
    showHeaderNavbar: true,
    showBannerV1: true,
    showBannerV2: false,
    showButton: false,
    buttonText: "",
    showAddressSearch: true,
    bannerSrc: "/assets/images/process-barter-banner.png",
  },
  [memberPath.findMembers]: {
    title: "Find new friends who want to barter",
    showHeaderNavbar: true,
    showBannerV1: true,
    showBannerV2: false,
    showButton: false,
    buttonText: "Process Barter",
    showAddressSearch: true,
    redirectUrl: memberPath.processBarter,
    bannerSrc:
      "/assets/svg/findMemberBannerMobile.svg 900w, /assets/svg/findMemberBanner.svg 1200w",
  },
  [memberPath.memberProfile]: {
    title: "",
    showHeaderNavbar: true,
    showBannerV1: false,
    showBannerV2: true,
    showButton: false,
    buttonText: "",
    showAddressSearch: true,
  },
  [memberPath.bitsGift]: {
    title: "",
    showHeaderNavbar: true,
    showBannerV1: false,
    showBannerV2: false,
    showButton: false,
    buttonText: "",
    showAddressSearch: true,
  },
  [memberPath.statement]: {
    title: "",
    showHeaderNavbar: true,
    showBannerV1: false,
    showBannerV2: false,
    showButton: false,
    buttonText: "",
    showAddressSearch: false,
    bannerSrc: "",
  },
  [memberPath.reportOffer]: {
    title: "",
    showHeaderNavbar: true,
    showBannerV1: false,
    showBannerV2: false,
    showButton: false,
    buttonText: "",
    showAddressSearch: true,
  },
  [memberPath.acceptBits]: {
    title: "",
    text: "Accept BITS<sup>TM<sup>    Gift",
    showHeaderNavbar: true,
    showBannerV1: true,
    showBannerV2: false,
    showButton: false,
    buttonText: "",
    showAddressSearch: true,
    bannerSrc: "/assets/images/acceptBitsBanner.png",
    isTradmarkText: true,
    greyBannerStyles: {
      title: {
        sm: "4rem",
        xs: "4.3rem",
      },
    },
  },

  [memberPath.successAcceptGifts]: {
    title: "",
    showHeaderNavbar: true,
    showBannerV1: false,
    showBannerV2: false,
    showButton: false,
    buttonText: "",
    showAddressSearch: true,
  },
  [memberPath.sentBitsGift]: {
    title: "",
    text: "Gift BITS<sup>TM<sup>",
    showHeaderNavbar: true,
    showBannerV1: false,
    showBannerV2: true,
    showButton: true,
    buttonText: "Give Now",
    redirectUrl: memberPath.userInfo,
    showAddressSearch: true,
    isTradmarkText: true,
    greyBannerStyles: {
      minHeight: {
        sm: "408px",
        xs: "366px",
      },
    },
  },
  [memberPath.userInfo]: {
    title: "",
    showHeaderNavbar: true,
    showBannerV1: false,
    showBannerV2: false,
    showButton: false,
    buttonText: "",
    showAddressSearch: true,
  },
  [memberPath.giftDetail]: {
    title: "",
    showHeaderNavbar: true,
    showBannerV1: false,
    showBannerV2: false,
    showButton: false,
    buttonText: "",
    showAddressSearch: true,
  },
  [memberPath.editCalender]: {
    title: "",
    showHeaderNavbar: false,
    showBannerV1: false,
    showBannerV2: false,
    showButton: false,
    buttonText: "",
    showAddressSearch: false,
  },
  [memberPath.feedback]: {
    title: "",
    showHeaderNavbar: true,
    showBannerV1: false,
    showBannerV2: false,
    showButton: false,
    buttonText: "",
    showAddressSearch: true,
  },
  [memberPath.reportIssue]: {
    title: "",
    showHeaderNavbar: true,
    showBannerV1: false,
    showBannerV2: false,
    showButton: false,
    buttonText: "",
    showAddressSearch: true,
  },
  [memberPath.reportProblem]: {
    title: "",
    showHeaderNavbar: true,
    showBannerV1: false,
    showBannerV2: false,
    showButton: false,
    buttonText: "",
    showAddressSearch: true,
  },
  [memberPath.exploreBusiness]: {
    title: "",
    showHeaderNavbar: true,
    showBannerV1: false,
    showBannerV2: false,
    showButton: false,
    buttonText: "",
    showAddressSearch: true,
  },
  [memberPath.feedbackSuccess]: {
    title: "",
    showHeaderNavbar: true,
    showBannerV1: false,
    showBannerV2: false,
    showButton: false,
    buttonText: "",
    showAddressSearch: true,
  },
  [memberPath.reviewRating]: {
    title: "",
    showHeaderNavbar: true,
    showBannerV1: false,
    showBannerV2: false,
    showButton: false,
    buttonText: "",
    showAddressSearch: true,
  },
  [memberPath.reportReview]: {
    title: "",
    showHeaderNavbar: true,
    showBannerV1: false,
    showBannerV2: false,
    showButton: false,
    buttonText: "",
    showAddressSearch: true,
  },
  [memberPath.editReview]: {
    title: "",
    showHeaderNavbar: true,
    showBannerV1: false,
    showBannerV2: false,
    showButton: false,
    buttonText: "",
    showAddressSearch: true,
  },
  [memberPath.replyReview]: {
    title: "",
    showHeaderNavbar: true,
    showBannerV1: false,
    showBannerV2: false,
    showButton: false,
    buttonText: "",
    showAddressSearch: true,
  },
  [memberPath.editReviewReply]: {
    title: "",
    showHeaderNavbar: true,
    showBannerV1: false,
    showBannerV2: false,
    showButton: false,
    buttonText: "",
    showAddressSearch: true,
  },
  [memberPath.membership]: {
    title: "",
    showHeaderNavbar: true,
    showBannerV1: false,
    showBannerV2: true,
    showButton: false,
    buttonText: "",
    redirectUrl: memberPath.userInfo,
    showAddressSearch: true,
  },
  [memberPath.cancelMembership]: {
    title: "",
    showHeaderNavbar: true,
    showBannerV1: false,
    showBannerV2: false,
    showButton: false,
    buttonText: "",
    redirectUrl: "",
    showAddressSearch: true,
  },
};
