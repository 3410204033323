import { createSlice } from "@reduxjs/toolkit";
import { UserProfile } from "src/admin/types/api";
import { MEMBER } from "src/member/constants/enums";
import { appLogout } from "src/redux/thunks";
import { getMemberProfile } from "../thunks/profile";

type UserProfileData = {
  information: UserProfile;
  userLocation: {
    latitude: number;
    longitude: number;
    formatted_address: string;
    types?: string[];
    address_components?: any[];
  };
  offerRadius: number;
  memberRadius: number;
  fcmToken: string;
  type: MEMBER;
};

const initialState: UserProfileData = {
  information: {} as UserProfile,
  userLocation: {
    latitude: null,
    longitude: null,
    formatted_address: null,
    address_components: [],
    types: [],
  },
  offerRadius: 20,
  memberRadius: 20,
  fcmToken: "",
  type: MEMBER.CUSTOMER_PERSONAL,
};
const userProfileDetails = createSlice({
  name: "userProfileDetails",
  initialState,
  reducers: {
    fillProfileInfo: (state, action) => {
      const responseData = action.payload.data.data;

      const informationData = {
        ...responseData,
        ...responseData?.userProfile,
        ...responseData?.business,
      };

      state.information = informationData;
    },
    setUserLocation: (state, action) => {
      state.userLocation = action.payload;
    },
    setFcmToken: (state, action) => {
      state.fcmToken = action.payload;
    },
    setRadius: (state, action) => {
      state[action.payload.name] = action?.payload?.value;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getMemberProfile.fulfilled, (state, action) => {
      const responseData = action.payload.data.data;

      const informationData = {
        ...responseData,
        ...responseData?.userProfile,
        ...responseData?.business,
      };
      state.type = responseData.business
        ? MEMBER.CUSTOMER_BUSINESS
        : MEMBER.CUSTOMER_PERSONAL;
      /*  responseData?.type === MEMBER.CUSTOMER_PERSONAL
          ? responseData.personalProfile?.userProfile
          : responseData.businessProfile?.userProfile; */

      state.information = informationData;
    });
    builder.addCase(appLogout.fulfilled, (state, _action) => {
      state.information = initialState.information;
      state.type = initialState.type;
      state.userLocation = initialState.userLocation;
    });
  },
});

export const { fillProfileInfo, setUserLocation, setRadius, setFcmToken } =
  userProfileDetails.actions;
export default userProfileDetails.reducer;
