import { FieldErrors } from "react-hook-form";
import { deleteCookie, setCookie } from "./cookies";

export const ACCESS_TOKEN = "accessToken";
export const REFRESH_TOKEN = "refreshToken";
export const LAST_ACTIVE = "lastActive";
export const EXPIRY_TIME = 1000 * 60;
export const SESSION_WATCH_TIME = 10 * 1000;

export const userSessionActive = (value: {
  accessToken: string;
  refreshToken: string;
}) => {
  // localStorage.setItem(ACCESS_TOKEN, value.accessToken);
  setCookie(ACCESS_TOKEN, value.accessToken, 7);
  setCookie(REFRESH_TOKEN, value.refreshToken, 7);
  setCookie(LAST_ACTIVE, new Date().toISOString(), 7);
  // localStorage.setItem(ACCESS_TOKEN, value.accessToken);
  // localStorage.setItem(REFRESH_TOKEN, value.refreshToken);
  // localStorage.setItem(LAST_ACTIVE, new Date().toISOString());
};
export const userSessionInactive = () => {
  deleteCookie(ACCESS_TOKEN);
  deleteCookie(REFRESH_TOKEN);
  deleteCookie(LAST_ACTIVE);

  // localStorage.removeItem(ACCESS_TOKEN);
  // localStorage.removeItem(REFRESH_TOKEN);
  // localStorage.removeItem(LAST_ACTIVE);
};

export const getError = (name: string, errors: FieldErrors) => {
  // if (!Object.keys(errors).length) return undefined;

  return name
    .split(".")
    .map((item) => item.replaceAll("[", "").replaceAll("]", ""))
    .reduce((prev, curr) => (prev ? prev[curr] : prev), errors as any);
};
