import { Box } from "@mui/material";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { memberProfileLayoutData } from "src/member/constants/path/path";
import LogoHeader from "src/shared/components/common/LogoHeader";
import { getStyles } from "src/shared/styles/theme";
import defaultStyles, { StylesClasses } from "./styles";

export const getText = (text: string) => {
  return text.split("<sup>");
};

const ProfileLayout = () => {
  const { pathname } = useLocation();

  const navigate = useNavigate();

  const { text, disableNavBack, showTitleWrapper } = memberProfileLayoutData?.[
    pathname
  ] || {
    text: "",
    disableNavBack: true,
    showTitleWrapper: true,
  };

  const styles = getStyles<StylesClasses>(defaultStyles);

  return (
    <Box {...styles("wrapper")}>
      <Box {...styles("logo")}>
        <LogoHeader />
      </Box>
      {showTitleWrapper && (
        <Box {...styles("titleWrapper")}>
          {!disableNavBack && (
            <Box
              component="img"
              src="/assets/svg/left-arrow.svg"
              alt="icon"
              onClick={() => navigate(-1)}
              {...styles("leftArrow")}
            />
          )}
          {getText(text).length > 1 ? (
            <Box {...styles("title")}>
              {getText(text)[0]}
              <Box component="span" {...styles("trademark")}>
                <sup>{getText(text)[1]}</sup>
              </Box>{" "}
              {getText(text)[2]}
            </Box>
          ) : (
            <Box {...styles("title")}>{text}</Box>
          )}
        </Box>
      )}
      <Outlet />
    </Box>
  );
};

export default ProfileLayout;
