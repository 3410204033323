import { createStyles } from "src/shared/styles/theme";

export type StylesClasses = keyof typeof styles;

const styles = createStyles({
  selectWrapper: {
    minWidth: "80px",
    borderTopRightRadius: "0px",
  },

  wrapper: {
    position: "relative",
  },

  customWrapper: {
    "& .MuiInputBase-root": {
      border: "none",
      borderTopLeftRadius: "12px",
      borderBottomLeftRadius: "12px",
      height: { xs: "38px", sm: "50px" },
      width: "80px",
    },
    minWidth: "80px",
    position: "absolute",
    top: "24px",
    left: "1px",
    zIndex: "1",
  },

  phoneCustom: {
    position: "relative",
    "& .MuiInputBase-root": {
      borderTopRightRadius: "12px",
      borderBottomRightRadius: "12px",
    },
    label: {
      position: "relative",
    },
    minWidth: "80px",
  },

  errorCustom: {},

  withHelperText: {
    display: "flex",
    flexDirection: "column",
  },
});

export default styles;
