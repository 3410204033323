import { PayloadAction, createSlice } from "@reduxjs/toolkit";

type Sample = {
  random: string;
};

const initialState: Sample = {
  random: "",
};
const sampleDetails = createSlice({
  name: "SampleDetails",
  initialState,
  reducers: {
    setSampleData: (state, action: PayloadAction<{ data: string }>) => {
      state.random = action.payload.data;
    },
  },
});

export const { setSampleData } = sampleDetails.actions;
export default sampleDetails.reducer;
