import { PayloadAction, createSlice } from "@reduxjs/toolkit";

type Session = {
  sessionExpiration: {
    isActive: boolean;
    time: number;
  };
};

const initialState: Session = {
  sessionExpiration: {
    isActive: false,
    time: 10 * 60 * 1000,
  },
};
const sessionDetails = createSlice({
  name: "sessionDetails",
  initialState,
  reducers: {
    setSession: (state, action: PayloadAction<Session>) => {
      state.sessionExpiration = action.payload.sessionExpiration;
    },
  },
});

export const { setSession } = sessionDetails.actions;
export default sessionDetails.reducer;
