import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { ProfileTypeFormData } from "src/member/components/feature/Profile/Common";
import { clearSignUpForm } from "../../createActions/clearSignupForm";
import { logoutUser } from "src/redux/actions";

type PersonalInfo = { formData: ProfileTypeFormData };

const initialState: PersonalInfo = {
  formData: {
    firstName: "",
    lastName: "",
    userName: "",
    phoneNumber: "",
    countryCode: "+1",
    address: {
      province: "",
      city: "",
      country: "Canada",
      addressLine1: "",
      apartment: "",
      postalCode: "",
    },
  },
};

const personalInfoSlice = createSlice({
  name: "personalInfo",
  initialState,
  reducers: {
    setPersonalInfo: (state, action: PayloadAction<ProfileTypeFormData>) => {
      state.formData = action.payload;
    },
    clearPersonalInfo: (state) => {
      state.formData = initialState.formData;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(clearSignUpForm, (state, _action) => {
      state.formData = initialState.formData;
    });
    builder.addCase(logoutUser, (state, _action) => {
      state.formData = initialState.formData;
    });
  },
});

export const { setPersonalInfo, clearPersonalInfo } = personalInfoSlice.actions;
export default personalInfoSlice.reducer;
