import { PayloadAction, createSlice } from "@reduxjs/toolkit";

type initialStateType = {
  count: number;
  feedbackCount: number;
};

const initialState: initialStateType = {
  count: null,
  feedbackCount: null,
};
const notificationCount = createSlice({
  name: "notificationCount",
  initialState,
  reducers: {
    setNotificationCount: (state, action: PayloadAction<number>) => {
      state.count = action.payload;
    },
    setFeedBackCount: (state, action: PayloadAction<number>) => {
      state.feedbackCount = action.payload;
    },
  },
});

export const { setNotificationCount, setFeedBackCount } =
  notificationCount.actions;
export default notificationCount.reducer;
