import { PayloadAction, createSlice } from "@reduxjs/toolkit";

type SignUp = {
  email: string;
};

const initialState: SignUp = {
  email: "",
};
const signUpDetails = createSlice({
  name: "signUpDetails",
  initialState,
  reducers: {
    setSignUpEmailAddress: (
      state,
      action: PayloadAction<{ email: string }>,
    ) => {
      state.email = action.payload.email;
    },
  },
});

export const { setSignUpEmailAddress } = signUpDetails.actions;
export default signUpDetails.reducer;
