import { createStyles } from "src/shared/styles/theme";

export type StylesClasses = keyof typeof styles;

const styles = createStyles({
  radio: {
    display: "flex",
    flexDirection: "column",
    gap: "2px",
  },
  input: {},
  label: {
    fontSize: "22px",
    fontWeight: "500",
  },
  commonWrapper: {
    display: "flex",
    gap: "10px",
    padding: "16px",
    borderRadius: "12px",
    borderBottomWidth: "1px",
    borderBottomStyle: "solid",
    borderBottomColor: "custom.greyish.6",
    cursor: "pointer",
    mb: "10px",
  },
  cardWrapper: {
    background: "custom.text.white.100",
  },
  selectedCardWrapper: {
    background: "linear-gradient(0deg, #E0FFED 0%, #E0FFED 100%), #FFF",
  },
  img: {
    height: "43px",
    width: "64px",
    borderRadius: "5px",
  },
  title: {
    fontSize: "18px",
    fontWeight: "600",
    color: "custom.text.dark",
  },
  description: {
    fontSize: "14px",
    color: "custom.greyish.4",
  },
});

export default styles;
