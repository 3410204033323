import { CombinedState, StateFromReducersMapObject } from "@reduxjs/toolkit";
import { TypedUseSelectorHook, useSelector } from "react-redux";
import persistReducer from "redux-persist/es/persistReducer";
import storage from "redux-persist/lib/storage";
import memberDetails from "./slices/memberDetails";
import navigationState from "./slices/navigationState";
import signUpData from "./slices/onboarding/signUpData";
import businessInfo from "./slices/profileSetup/businessInfo";
import memberType from "./slices/profileSetup/memberType";
import personalInfo from "./slices/profileSetup/personalInfo";
import sampleDetails from "./slices/sample";
import servicesOffer from "./slices/servicesOffer";
import chatNotificationSlice from "./slices/chatNotification";
import restrictedRoutes from "./slices/restrictedRoutes";

export const sampleDetailsConfig = {
  key: "sampleDetails",
  storage: storage,
};

export const memberTypeConfig = {
  key: "memberType",
  storage: storage,
};

export const personalInfoConfig = {
  key: "personalInfo",
  storage: storage,
};

export const businessInfoConfig = {
  key: "businessInfo",
  storage: storage,
};

export const signUpDataConfig = {
  key: "signUpData",
  storage: storage,
};

export const memberDetailsConfig = {
  key: "memberDetails",
  storage: storage,
};

export const navigationStateConfig = {
  key: "navigationState",
  storage: storage,
};

const servicesOfferConfig = {
  key: "servicesOffer",
  storage: storage,
};

const restrictedRoutesConfig = {
  key: "restrictedRoutes",
  storage: storage,
};

export const reducers = {
  memberSampleDetails: persistReducer(sampleDetailsConfig, sampleDetails),
  memberType: persistReducer(memberTypeConfig, memberType),
  personalInfo: persistReducer(personalInfoConfig, personalInfo),
  businessInfo: persistReducer(businessInfoConfig, businessInfo),
  signUpData: persistReducer(signUpDataConfig, signUpData),
  memberDetails: persistReducer(memberDetailsConfig, memberDetails),
  navigationState: persistReducer(navigationStateConfig, navigationState),
  servicesOffer: persistReducer(servicesOfferConfig, servicesOffer),
  chatNotification: persistReducer(servicesOfferConfig, chatNotificationSlice),
  restrictedRoutes: persistReducer(restrictedRoutesConfig, restrictedRoutes),
};

type MemberRootState = {
  root: CombinedState<StateFromReducersMapObject<typeof reducers>>;
};

export const useMemberAppSelector: TypedUseSelectorHook<MemberRootState> =
  useSelector;
