import { AdminData } from "./slices/adminDetails";

export const adminDetailsInitials: AdminData = {
  adminProfile: {
    authMethod: "",
    checkOutStatus: "",
    created_at: "",
    email: "",
    id: "",
    invitedBy: "",
    lastLogin: "",
    password: "",
    roleId: "",
    socialId: "",
    statusId: "",
    updated_at: "",

    userProfile: {
      addressId: "",
      created_at: "",
      firstName: "",
      id: "",
      lastName: "",
      mobileNumber: "",
      profilePhoto: "",
      socialHandleId: "",
      subRoleId: "",
      updated_at: "",
      userId: "",
      userName: "",
      yourBio: "",
      email: "",
      business: {},
      authMethod: "",
      address: {},
    },
  },
  adminRole: {
    created_at: "",
    description: "",
    id: "",
    isActive: false,
    name: "",
    updated_at: "",
  },
};
