import styled from "@emotion/styled";
import { Box, Rating, RatingProps } from "@mui/material";
import { UseControllerProps, useController } from "react-hook-form";

const StyledRating = styled(Rating)({
  "& .MuiRating-iconFilled": {
    color: "#ff6d75",
  },
  "& .MuiRating-iconHover": {
    color: "#ff3d47",
  },
});

type RatingsProps<T = any> = UseControllerProps<T> &
  RatingProps & {
    height?: number;
    width?: number;
    defaultValue?: number;
    readOnly?: boolean;
    onChange?: (newValue: number) => void;
  };

const Ratings = ({
  control,
  name,
  rules,
  height,
  width,
  readOnly,
  defaultValue,
  onChange,
  ...rest
}: RatingsProps) => {
  const { field } = useController({
    control,
    name,
    rules,
  });

  return (
    <StyledRating
      name="customized-color"
      defaultValue={defaultValue}
      value={field.value}
      getLabelText={(value: number) =>
        `${value} Heart${value !== 1 ? "s" : ""}`
      }
      {...rest}
      // precision={0.5}
      readOnly={readOnly}
      onChange={(_, newValue) => field.onChange(newValue ?? field.value)}
      icon={
        <Box
          component="img"
          src="/assets/svg/filledRateIcon.svg"
          alt="icon"
          height={height}
          width={width}
        />
      }
      emptyIcon={
        <Box
          component="img"
          src="/assets/svg/emptyRateIcon.svg"
          alt="icon"
          height={height}
          width={width}
        />
      }
    />
  );
};

export default Ratings;
