import { PayloadAction, createSlice } from "@reduxjs/toolkit";

type InitialState = {
  data: { show: boolean };
  notification: { notificationCount: number };
};

const initialState: InitialState = {
  data: {
    show: false,
  },
  notification: {
    notificationCount: 0,
  },
};
const chatNotificationSlice = createSlice({
  name: "chatNotificationSlice",
  initialState,
  reducers: {
    setChatNotification: (state, action: PayloadAction<{ show: boolean }>) => {
      state.data = action.payload;
    },
    setNotification: (
      state,
      action: PayloadAction<{ notificationCount: number }>,
    ) => {
      state.notification = action.payload;
    },
  },
});

export const { setChatNotification, setNotification } =
  chatNotificationSlice.actions;
export default chatNotificationSlice.reducer;
