import { Box, FormHelperText, InputLabel, TextFieldProps } from "@mui/material";
import {
  Controller,
  FieldErrors,
  FieldValues,
  Path,
  PathValue,
  UseControllerProps,
} from "react-hook-form";
import { CustomStyles, getStyles } from "src/shared/styles/theme";
import { getError } from "src/shared/utils/common";
import RadioInput, { RadioProps } from "../Radio";
import defaultStyles, { StylesClasses } from "./styles";

type SelectProps<T> = UseControllerProps<T> &
  TextFieldProps & {
    errors?: FieldErrors;
    customStyles?: CustomStyles<StylesClasses>;
    radioInputCustomStyles?: RadioProps["customStyles"];
    disabled?: boolean;
    options: {
      label: string;
      value: string;
      description?: string;
    }[];
  };

export const RadioGroup = <T extends FieldValues>({
  name,
  control,
  label,
  fullWidth = true,
  errors,
  customStyles,
  radioInputCustomStyles,
  variant = "outlined",
  options,
  disabled,
  rules,
  ...rest
}: SelectProps<T>) => {
  const styles = getStyles<StylesClasses>(defaultStyles, customStyles);
  const error = getError(name, errors);

  return (
    <>
      <InputLabel
        shrink
        className="label"
        disabled={disabled}
        {...styles("label")}
        htmlFor={name}
        required={!!rules?.required && !!label}
      >
        {label}
      </InputLabel>
      <Box>
        <Controller
          render={({ field }) => (
            <Box {...styles("radio")}>
              {options.map((item) => {
                return (
                  <RadioInput
                    key={item.value}
                    label={item.label}
                    value={item.value}
                    description={item?.description}
                    customStyles={radioInputCustomStyles}
                    handleChange={() => field.onChange(item.value)}
                    checked={field.value === item.value}
                  />
                );
              })}
            </Box>
          )}
          defaultValue={"" as PathValue<T, Path<T>>}
          name={name as Path<T>}
          control={control}
          rules={rules}
          {...rest}
        />
        {!!error && (
          <FormHelperText {...styles("input")}>{error?.message}</FormHelperText>
        )}
      </Box>
    </>
  );
};
