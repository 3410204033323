import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { CustomStyles } from "src/shared/styles/theme";

type initials = {
  showSnackbar: boolean;
  message: string;
  duration?: number;
  styles?: CustomStyles<any>;
  severity: "error" | "success" | "warning";
  isUndo?: boolean;
  id?: string;
  undoToken?: string;
};

const initialState: initials = {
  showSnackbar: false,
  message: "",
  duration: 2000,
  styles: {},
  severity: "success",
  isUndo: false,
  id: "",
  undoToken: "",
};

const snackbarSlice = createSlice({
  name: "snackbar",
  initialState,
  reducers: {
    displaySnackbar: (state, action: PayloadAction<initials>) => {
      state.showSnackbar = action.payload.showSnackbar;
      state.message = action.payload.message;
      state.styles = action.payload?.styles;
      state.severity = action.payload.severity;
      state.duration = action.payload?.duration;
      state.isUndo = action.payload?.isUndo;
      state.id = action.payload?.id;
      state.undoToken = action.payload?.undoToken;
    },
    hideSnackbar: (state) => {
      state.showSnackbar = false;
      state.message = "";
      state.styles = {};
      state.severity = "success";
      state.duration = 2000;
      state.isUndo = false;
      state.id = "";
      state.undoToken = "";
    },
  },
});

export const { displaySnackbar, hideSnackbar } = snackbarSlice.actions;
export default snackbarSlice.reducer;
