import { createStyles } from "src/shared/styles/theme";

export type StylesClasses = keyof typeof styles;

const styles = createStyles({
  wrapper: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    gap: "0px",
  },

  label: {
    fontSize: {
      xs: "14px",
      sm: "16px",
    },
    fontWeight: 400,
    color: "custom.greyish.4",
  },

  value: {
    fontSize: {
      xs: "14px",
      sm: "16px",
    },
    fontWeight: 400,
    color: "custom.text.black.100",
    wordBreak: "break-all",
  },
});

export default styles;
