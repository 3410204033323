import { createStyles } from "src/shared/styles/theme";

export type StylesClasses = keyof typeof styles;

const styles = createStyles({
  root: {
    "&.MuiPaper-root, .MuiAlert-root": {
      alignItems: "center",
      width: {
        xs: "90%",
        sm: "100%",
      },
      minHeight: {
        xs: "72px",
        sm: "auto",
      },
      borderRadius: "10px",
    },
    zIndex: 10000000,
  },
  alert: {
    marginRight: "30px",
    color: "#0F0F0F",
    width: {
      sm: "100%",
      xs: "200px",
    },
  },
  undoMsg: {
    textDecorationLine: "underline",
    cursor: "pointer",
  },

  alertBox: {
    width: {
      xs: "100%",
      sm: "100%",
    },
    "& .MuiAlert-icon": {
      flexBasis: { xs: "10%", sm: "none" },
    },
    "& .MuiAlert-message": {
      flexBasis: { xs: "90%", sm: "none" },
    },
  },
  crossIcon: {},
  messageWrapper: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    zIndex: 10000000,
    width: "100%",
  },
});

export default styles;
