import { combineReducers } from "redux";
import persistReducer from "redux-persist/es/persistReducer";
import storage from "redux-persist/lib/storage";
import { reducers as adminReducers } from "src/admin/redux/reducers";
import { reducers as memberReducers } from "src/member/redux/reducers";
import user from "./slices/user";
import session from "./slices/session";
import userProfileDetails from "src/member/redux/slices/userProfileDetails";
import snackbar from "./slices/snackbar";
const rootPersistConfig = {
  key: "root",
  storage: storage,
  whitelist: ["user"],
  blacklist: ["servicesOffer"],
};

const userConfig = {
  key: "user",
  storage: storage,
};

const sessionConfig = {
  key: "sessionConfig",
  storage: storage,
};

const snackbarConfig = {
  key: "snackBarConfig",
  storage: storage,
};

const userDetailConfig = {
  key: "userDetailConfig",
  storage: storage,
};

const currentReducers =
  process.env.REACT_APP_IS_ADMIN === "true" ? adminReducers : memberReducers;

const rootReducer = combineReducers({
  // signUpDetails: persistReducer(signupDetailsConfig, signUpDetails),
  ...currentReducers,
  user: persistReducer(userConfig, user),
  userProfileDetails: persistReducer(userDetailConfig, userProfileDetails),
  session: persistReducer(sessionConfig, session),
  snackbarStatus: persistReducer(snackbarConfig, snackbar),
});

export type RootState = ReturnType<typeof rootReducer>;

const persistedReducer = persistReducer(rootPersistConfig, rootReducer);

export default persistedReducer;
