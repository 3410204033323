import { createStyles } from "src/shared/styles/theme";

export type StylesClasses = keyof typeof styles;

const styles = createStyles({
  root: {
    display: "flex",
    color: "secondary.main",
    width: "100%",
    borderRadius: "100px",
    fontSize: "18px",
    fontWeight: 700,
    justifyContent: "center",
    alignItems: "center",
    gap: "10px",
    minWidth: 100,
    padding: "6px 25px",
    whiteSpace: "nowrap",
    "& .MuiButton-startIcon": {
      margin: 0,
    },
  },

  outlined: {
    color: "primary.main",
    fontWeight: 600,

    "&:disabled": {
      backgroundColor: "custom.disabled.btn",
      color: "secondary.main",
      border: "none",
    },
  },

  contained: {
    "&:disabled": {
      backgroundColor: "custom.disabled.btn",
      color: "secondary.main",
      border: "none",
    },
  },

  text: {
    color: "secondary.main",
  },

  circle: {
    color: "custom.white.100",
  },
  btnText: {},
  iconSrc: {
    marginRight: "4px",
  },
});

export default styles;
