import { PayloadAction, createSlice } from "@reduxjs/toolkit";

type StepsType = {
  currentStep: number;
  subStep: number;
};

type FormDataType = {};

type StepperData = {
  isCompleted: boolean;
  name: string;
  isActive: boolean;
  id: number;
  route?: string;
};
type initialState = {
  formData: FormDataType;
  stepperData: StepperData[];
  steps: StepsType;
};

const stepperData = [
  {
    isCompleted: false,
    name: "first",
    isActive: true,
    id: 1,
    route: "",
  },
  {
    isCompleted: false,
    name: "second",
    isActive: false,
    id: 2,
    route: "",
  },
  {
    isCompleted: false,
    name: "third",
    isActive: false,
    id: 3,
    route: "",
  },
  {
    isCompleted: false,
    name: "four",
    isActive: false,
    id: 4,
    route: "",
  },
];

const initialState: initialState = {
  formData: {},
  stepperData: stepperData,
  steps: {
    currentStep: 1,
    subStep: 1,
  },
};

const servicesOffer = createSlice({
  name: "servicesOffer",
  initialState,
  reducers: {
    setOfferSteps: (state, action: PayloadAction<StepsType>) => {
      state.steps = action.payload;
    },
    setServicesOfferFormData: (state, action: PayloadAction<FormDataType>) => {
      state.formData = action.payload;
    },
    setStepsData: (state, action: PayloadAction<StepperData>) => {
      state.stepperData = [...stepperData];
    },
  },
});

export const { setOfferSteps, setServicesOfferFormData } =
  servicesOffer.actions;
export default servicesOffer.reducer;
