import {
  createTheme,
  responsiveFontSizes,
  SxProps,
} from "@mui/material/styles";

declare module "@mui/material/styles" {
  interface TypographyVariants {
    [key: string]: any;
  }
  interface TypographyVariantsOptions {
    [key: string]: any;
  }
  interface Palette {
    [key: string]: any;
  }
  interface PaletteOptions {
    [key: string]: any;
  }
}

// Create a theme instance.
let theme = createTheme({
  palette: {
    primary: {
      main: "#0F0F0F",
    },
    secondary: {
      main: "#F1F1F1",
    },
    success: {
      main: "#16A413",
    },
    error: {
      main: "#D80621",
    },
    custom: {
      background: {
        facebook: "#1877F2",
        error: "#6c031114",
        avatar: "#CDF0F7",
        disabled: "#F7CDD3",
      },

      border: {
        error: "#F91111",
      },

      greyish: {
        "1": "#616161",
        "2": "#1E1E1E",
        "3": "#7D7D7D7A",
        "4": "#5E5E5E",
        "5": "#EBEBEB",
        "6": "#FCFCFC",
        "7": "#E8E8E8",
        "8": "#F5F5F5",
        "9": "#3E3E3E",
        "7d": {
          100: "#7D7D7D",
          48: "#7D7D7D7A",
          8: "#7D7D7D14",
        },
        11: "#4C5057",
        12: "#F1F1F1",
        13: "#D0D0D0",
        14: "#E8E8E8",
        15: "#F8F8F8",
        16: "#43ff6414",
        17: "#3c3c43D9",
        18: "#D2D2D2",
        19: "#61616166",
      },

      text: {
        subHeading: "#252525",
        input: "#121212",
        label: "4A2E32",
        avatar: "#023B48",
        disabled: "#900416",
        white: {
          100: "#ffffff",
          80: "#ffffffCC",
          54: "#ffffff8A",
          fd: "#FDFDFD",
          30: "#FDFDFD",
          20: "#D0D0D0",
        },
        black: {
          "100": "#000000",
          "54": "#0000008A",
          "09": "#090909",
        },
        green: {
          "01": "#0F6D0D",
        },
        yellow: {
          "01": "#553F16",
        },
        highlightedBlue: "#047790",
      },
      disabled: {
        btn: "#B0B0B0",
      },
    },
  },

  typography: {
    poppins: {
      regular: {
        fontFamily: "Poppins",
        fontWeight: "400",
      },

      light: {
        fontFamily: "Poppins",
        fontWeight: "300",
      },

      medium: {
        fontFamily: "Poppins",
        fontWeight: "500",
      },

      semiBold: {
        fontFamily: "Poppins",
        fontWeight: "600",
      },

      bold: {
        fontFamily: "Poppins",
        fontWeight: "700",
      },
    },
    montserrat: {
      light: {
        fontFamily: "Montserrat",
        fontWeight: "300",
      },
      medium: {
        fontFamily: "Montserrat",
        fontWeight: "500",
      },
      semiBold: {
        fontFamily: "Montserrat",
        fontWeight: "600",
      },
      bold: {
        fontFamily: "Montserrat",
        fontWeight: "700",
      },
    },
    roboto: {
      light: {
        fontFamily: "Roboto",
        fontWeight: "300",
      },
      regular: {
        fontFamily: "Roboto",
        fontWeight: "400",
      },
      medium: {
        fontFamily: "Roboto",
        fontWeight: "500",
      },
      bold: {
        fontFamily: "Roboto",
        fontWeight: "700",
      },
    },
    leagueSpartan: {
      light: {
        fontFamily: "League Spartan",
        fontWeight: "300",
      },
      regular: {
        fontFamily: "League Spartan",
        fontWeight: "400",
      },
      medium: {
        fontFamily: "League Spartan",
        fontWeight: "500",
      },
      bold: {
        fontFamily: "League Spartan",
        fontWeight: "700",
      },
    },
    fontFamily: ["Nunito Sans", "League Spartan", "poppins", "sans-serif"].join(
      ",",
    ),
  },
});

/**
 * Mui components styles overrides
 */
theme = createTheme(theme, {
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          "& *": {
            "&::-webkit-scrollbar ": {
              margin: "15px",
              width: "8px",
              height: "4px",
              borderRadius: "100px",
            },
            "&::-webkit-scrollbar-track": {
              backgroundColor: "transparent", //custom.greyish.18
              borderRadius: "100px",
              borderWidth: "0px",
              borderStyle: "solid",
              maxHeight: "3px",
            },
            "&::-webkit-scrollbar-thumb": {
              backgroundColor: "#5E5E5E", //error.main
              borderRadius: "100px",
              borderWidth: "1px",
              maxHeight: "10px",
            },
            "&::-moz-range-thumb": {
              backgroundColor: "#5E5E5E", //error.main
              borderRadius: "100px",
              borderWidth: "1px",
              maxHeight: "10px",
            },
            "&::-webkit-scrollbar-thumb:hover": {
              zoom: 1.1,
            },
          },
        },
      },
    },

    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: "capitalize",
          boxShadow: "none",
          zIndex: 0,
        },
      },
    },

    MuiSelect: {
      select: {
        padding: "0",
        "&.MuiInputBase-root": {
          "&.MuiSelect-select": {
            padding: 0,
          },
        },
        "&:focus": {
          backgroundColor: "transparent",
        },
        "&.Mui-focused": {
          borderBottomRightRadius: "0",
          borderBottomLeftRadius: "0",
        },
      },
      root: {
        fontSize: "0.9rem",
      },
    },
    MuiMenuItem: {
      root: {
        fontSize: "0.9rem",
      },
    },

    MuiDrawer: {
      styleOverrides: {
        root: {
          height: "90vh",
        },
        paper: {
          border: "none",
        },
      },
    },

    MuiInputLabel: {
      styleOverrides: {
        root: {
          fontSize: "16px",
          paddingInline: "16px",
          color: theme.palette.custom.greyish["1"],
          [theme.breakpoints.down("sm")]: {
            fontSize: "16px",
          },
          transform: "translate(0px, 20px) scale(1)",
          fontWeight: "600",
          "&.Mui-focused": {
            color: theme.palette.custom.greyish["1"],
          },
          "&.MuiInputLabel-shrink": {
            transform: "translate(0px, -6px) scale(0.75)",
          },
          "&.Mui-error": {
            borderColor: `${theme.palette.error.main} !important`,
            color: theme.palette.error.main,
          },
          lineHeight: 1,
          zIndex: 2,
        },

        asterisk: {
          marginLeft: "0px",
          color: theme.palette.custom.text.label,
          "&$error": {
            color: theme.palette.error.main,
          },
        },
      },
    },

    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          background: "#000000",
        },
        tooltipArrow: {
          fontSize: "1.5em",
          background: "#000000",
          borderWidth: "1px",
          borderStyle: "solid",
          borderColor: "#000",
          boxShadow: "0px 2px 7px 0px rgba(0, 0, 0, 0.12)",
          padding: "16px",
          borderRadius: "5px",
          zIndex: 999,
          "&.error": {
            background: "#FFEBEB",
            border: "1px solid #F80000",
          },
        },
        arrow: {
          color: "#000",
          "&.error": {
            color: "#FFEBEB",
            zIndex: 0,
          },
        },
      },
    },

    MuiInputBase: {
      styleOverrides: {
        root: {
          "label + &": {
            [theme.breakpoints.down("sm")]: {},
          },
          width: "100%",
          boxSizing: "border-box",
          borderRadius: "12px",
          fontSize: "16px",
          color: "#121212",
          [theme.breakpoints.down("sm")]: {
            fontSize: "16px",
            // height: "43px",
          },

          "&.Mui-error": {
            borderColor: `${theme.palette.error.main} !important`,
            color: "#121212",
          },
          "&.MuiInput-root": {
            marginTop: "10px",
            padding: "5px 14px",
          },
          "&.MuiOutlinedInput-input": {
            padding: "10px",
            lineHeight: 1,
          },
          "&.MuiFilledInput-root": {
            backgroundColor: "transparent !important",
            paddingLeft: "2px",
            lineHeight: 1,
          },
          "&.MuiFilledInput-root.Mui-disabled": {
            // backgroundColor: "#61616166 !important",
            backgroundColor: theme.palette.custom.text.white["100"],
            "&.MuiFilledInput-root.Mui-disabled:before": {
              borderBottomStyle: "none",
            },
          },
          "&.MuiOutlinedInput-root, .Mui-disabled": {
            // backgroundColor: "#61616166 !important",
            backgroundColor: theme.palette.custom.text.white["100"],
            "&.MuiOutlinedInput-root, .Mui-disabled:before": {
              borderBottomStyle: "none",
              borderRadius: "4px",
            },
          },

          "&.Mui-focused": {
            borderColor: "#4A2E32",
          },

          "&.Mui-disabled": {
            WebkitTextFillColor: theme.palette.primary.main,
            color: theme.palette.primary.main,
            // backgroundColor: "#61616166",
            backgroundColor: theme.palette.custom.text.white["100"],
            borderRadius: "0px",
            borderWidth: "1px",
            borderColor: theme.palette.custom.greyish["1"],
            borderBottomStyle: "solid",
            "&.MuiInput-root:before": {
              borderBottomStyle: "none",
            },
          },
        },
      },
    },

    MuiInput: {
      styleOverrides: {
        root: {
          "label + &": {
            [theme.breakpoints.down("sm")]: {},
          },
          width: "100%",
          boxSizing: "border-box",
          borderRadius: "12px",
          fontSize: "16px",
          color: "#121212",
          [theme.breakpoints.down("sm")]: {
            fontSize: "16px",
            // height: "43px",
          },
          "&.Mui-error": {
            borderColor: `${theme.palette.error.main} !important`,
            color: "#121212",
          },
          "&.MuiInput-root": {
            marginTop: "10px",
            padding: "5px 14px",
          },
          "&.MuiOutlinedInput-input": {
            padding: "10px",
            lineHeight: 1,
          },
          "&.MuiFilledInput-root": {
            backgroundColor: "transparent !important",
            paddingLeft: "2px",
            lineHeight: 1,
          },
          "&.MuiFilledInput-root.Mui-disabled": {
            // backgroundColor: "#61616166 !important",
            backgroundColor: theme.palette.custom.text.white["100"],
            "&.MuiFilledInput-root.Mui-disabled:before": {
              borderBottomStyle: "none",
            },
          },

          "&.Mui-focused": {
            borderColor: "#4A2E32",
          },
          "&.Mui-disabled": {
            WebkitTextFillColor: theme.palette.primary.main,
            color: theme.palette.primary.main,
            backgroundColor: theme.palette.custom.text.white["100"],
            borderRadius: "0px",
            borderWidth: "1px",
            borderColor: theme.palette.custom.greyish["1"],
            borderBottomStyle: "solid",
            "&.MuiInput-root:before": {
              borderBottomStyle: "none",
            },
          },
        },
      },
    },

    MuiFormHelperText: {
      styleOverrides: {
        root: {
          fontSize: "14px",
          fontWeight: "500",
          color: theme.palette.error.main,
          position: "absolute",
          bottom: "-27px",
          paddingLeft: "16px",
          [theme.breakpoints.down("sm")]: {
            fontSize: "12px",
            bottom: "-40px",
          },
          MuiDivider: {
            styleOverrides: {
              root: {
                borderBottomWidth: "1px",
                borderBottomStyle: "solid",
                borderBottomColor: theme.palette.primary.light,
              },
            },
          },
        },
      },
    },
  },
});

const customTypography = {};

theme = {
  ...theme,
  typography: {
    ...theme.typography,
    ...customTypography,
  },
};

theme = responsiveFontSizes(theme);

export default theme;

export type Styles<T extends string = string> = Record<T, SxProps>;
export type CustomStyles<T extends string = string> = Partial<Styles<T>>;

export const getStyles = <T extends string = string>(
  mainStyles: Styles,
  customStyles?: CustomStyles,
) => {
  return (names: T | T[], sx?: SxProps): { sx: SxProps } => {
    let ms: SxProps = {},
      cs: SxProps = {},
      es = sx ?? {};

    if (Array.isArray(names)) {
      ms = {};

      cs = {};

      names.forEach((name) => {
        ms = {
          ...ms,

          ...mainStyles[name],
        } as SxProps;

        const newCs = customStyles?.[name];

        if (newCs)
          cs = {
            ...cs,

            ...newCs,
          } as SxProps;
      });
    } else {
      ms = mainStyles[names];

      cs = customStyles?.[names] ?? {};
    }

    const resultantStyles = { ...ms, ...es, ...cs } as SxProps;

    return { sx: resultantStyles };
  };
};

export const createStyles = <T extends string>(data: Styles<T>) => data;
