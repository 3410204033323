import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { AUTH_METHODS } from "src/member/constants/enums";
import { clearSignUpForm } from "../createActions/clearSignupForm";
import { logoutUser } from "src/redux/actions";

type MemberDetailsType = {
  type?: AUTH_METHODS;
  userId: string;
  checkOutStatus: string;
};

type InitialState = {
  data: MemberDetailsType;
};

const initialState: InitialState = {
  data: { type: AUTH_METHODS.CUSTOM, userId: "", checkOutStatus: "" },
};
const memberDetailsSlice = createSlice({
  name: "memberDetails",
  initialState,
  reducers: {
    setMemberDetails: (state, action: PayloadAction<MemberDetailsType>) => {
      state.data = { ...action.payload };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(clearSignUpForm, (state, _action) => {
      state.data = initialState.data;
    });
    builder.addCase(logoutUser, (state, _action) => {
      state.data = initialState.data;
    });
  },
});

export const { setMemberDetails } = memberDetailsSlice.actions;
export default memberDetailsSlice.reducer;
