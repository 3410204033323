import { createStyles } from "src/shared/styles/theme";

export type StylesClasses = keyof typeof styles;

const styles = createStyles({
  wrapper: {
    backgroundImage: {
      lg: "url('/assets/images/pageNotFound.png')",
      sm: "url('/assets/images/polarTablet.png')",
      xs: "url('/assets/images/polarMobile.png')",
    },
    backgroundRepeat: {
      sm: "no-repeat",
      xs: "repeat",
    },
    backgroundSize: {
      sm: "cover",
      xs: "contain",
    },
    // backgroundPosition: "left center",
    height: "100vh",
    display: "flex",
    alignItems: "center",
    "&::after": {
      content: '""',
      height: "100vh",
      width: "100%",
      position: "absolute",
      top: 0,
      background:
        "linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(255,255,255,0.3660057773109244) 50%, rgba(255,255,255,0) 60%)",
    },
  },

  errorWrapper: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    padding: "0px 8%",
  },

  error: {
    fontSize: {
      lg: "200px",
      sm: "150px",
      xs: "100px",
    },
    height: {
      lg: "225px",
      sm: "180px",
      xs: "120px",
    },
    typography: "leagueSpartan.bold",
    color: "custom.text.black.100",
    zIndex: 999,
  },

  pageNotFound: {
    fontSize: {
      sm: "28px",
      xs: "22px",
    },
    typography: "leagueSpartan.medium",
    color: "custom.text.black.100",
    width: {
      sm: "400px",
      xs: "80%",
    },
    marginBottom: "30px",
    zIndex: 999,
  },
  logo: {
    cursor: "pointer",
  },
  barteritLogo: {
    position: "absolute",
    top: "30px",
    left: {
      lg: "120px",
      sm: "70px",
      xs: "40px",
    },
    zIndex: 199,
  },
});

export default styles;
