import { Box, FormHelperText, InputLabel, TextFieldProps } from "@mui/material";
import {
  Controller,
  FieldPathValue,
  FieldValues,
  Path,
  UseControllerProps,
} from "react-hook-form";
import { CustomStyles, getStyles } from "src/shared/styles/theme";
import defaultStyles, { StylesClasses } from "./styles";

type SelectProps<T> = UseControllerProps<T> &
  TextFieldProps & {
    customStyle?: CustomStyles<StylesClasses>;
    disabled?: boolean;
    defaultValue?: FieldPathValue<FieldValues, any> | string;
    clickAble?: boolean;
    handleClick?: (e: any) => void;
    options: {
      image?: string;
      name: string;
      id: number;
      description?: string;
      updated_at?: string;
      created_at?: string;
    }[];
  };

export const SelectInput = <T extends FieldValues>({
  name,
  control,
  label,
  fullWidth = true,
  customStyle,
  variant = "outlined",
  options,
  defaultValue = "",
  disabled,
  rules,
  clickAble = false,
  handleClick,
  ...rest
}: SelectProps<T>) => {
  const styles = getStyles<StylesClasses>(defaultStyles, customStyle);

  return (
    <>
      <Controller
        render={({ field, fieldState }) => (
          <>
            <Box {...styles("wrapper")}>
              {label && (
                <InputLabel
                  shrink
                  className="label"
                  disabled={disabled}
                  {...styles("label")}
                  required={!!rules?.required}
                  htmlFor={name}
                >
                  {label}
                </InputLabel>
              )}
              <Box {...styles("selectInputWrapper")}>
                {options?.map((item, i) => {
                  return (
                    <Box
                      {...styles([
                        "commonWrapper",
                        field.value.id === item.id
                          ? "selectedCardWrapper"
                          : "cardWrapper",
                      ])}
                      onClick={() => {
                        field.onChange(item);
                        clickAble && handleClick(item);
                      }}
                      ref={field.ref}
                      onBlur={field.onBlur}
                    >
                      {item?.image && (
                        <Box component="img" src={item?.image} alt="image" />
                      )}
                      <Box {...styles("title")}>{item?.name}</Box>
                      {field.value.id === item.id && (
                        <Box
                          component="img"
                          src="/assets/svg/stepperCheck.svg"
                          alt="icon"
                          {...styles("checked")}
                        />
                      )}
                    </Box>
                  );
                })}
              </Box>
            </Box>
            {!!fieldState.error?.message && (
              <FormHelperText {...styles("input")}>
                {fieldState.error?.message}
              </FormHelperText>
            )}
          </>
        )}
        name={name as Path<T>}
        control={control}
        defaultValue={defaultValue}
        rules={rules}
        {...rest}
      />
    </>
  );
};
