import { FirebaseApp, initializeApp } from "firebase/app";
import { Messaging, getMessaging, getToken } from "firebase/messaging";
import { useEffect } from "react";
import { setFcmToken } from "./member/redux/slices/userProfileDetails";
import { useAppDispatch } from "./redux/store";

interface FirebaseConfig {
  apiKey: string;
  authDomain: string;
  projectId: string;
  storageBucket: string;
  messagingSenderId: string;
  appId: string;
  measurementId: string;
}

const firebaseConfig: FirebaseConfig = {
  apiKey: "AIzaSyAcrcd1DE-axvRkpscYg66VnDgM2vRPsRo",
  authDomain: "tokyo-comfort-395522.firebaseapp.com",
  projectId: "tokyo-comfort-395522",
  storageBucket: "tokyo-comfort-395522.appspot.com",
  messagingSenderId: "208542249288",
  appId: "1:208542249288:web:bb7b4a7c9250691d00837d",
  measurementId: "G-8G5FSN235F",
};

const app: FirebaseApp = initializeApp(firebaseConfig);
let messaging: Messaging = getMessaging(app);

const FirebaseInitializer = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    const generateToken = async (): Promise<void> => {
      const permission: NotificationPermission =
        await Notification.requestPermission();

      if (permission === "granted") {
        console.log("Notification permission granted.");

        const currentToken = await getToken(messaging, {
          vapidKey:
            "BEV4PxeEC8cnnmkk4ssFrgq0tLZhKTsDIkIfUzUHjaPerj57EGyyEL37UQB8mXvQeX-SSuKuCf1BQK3Wv0LkBms",
        });

        if (currentToken) {
          dispatch(setFcmToken(currentToken));
        } else {
          console.log("Cannot get token");
        }
      } else {
        console.log("Do not have permission!");
      }
    };

    generateToken();
  }, [dispatch]);

  return <></>;
};

export { FirebaseInitializer, messaging };
