import {
  Box,
  FormControlProps,
  FormHelperText,
  InputAdornment,
  InputBaseProps,
  TextField,
} from "@mui/material";
import {
  Controller,
  FieldErrors,
  FieldValues,
  UseControllerProps,
} from "react-hook-form";
import ViewInput from "src/member/components/common/ViewInput";
import { CustomStyles, getStyles } from "src/shared/styles/theme";
import defaultStyles, { StylesClasses } from "./styles";
import ToolTip from "../../Tooltip";
import useWindowDimensions from "src/shared/hook/useWindowDimensions";

type InputProps<T> = UseControllerProps<T> &
  FormControlProps &
  InputBaseProps & {
    label?: string | React.ReactNode;
    errors?: FieldErrors;
    maxLength?: number;
    labelPos?: boolean;
    labelPosStyles?: boolean;
    readOnly?: boolean;
    showAdornment?: boolean;
    adornmentPos?: "start" | "end";
    iconSrc?: string;
    placeholder?: string | React.ReactNode;
    onKeyDown?: any;
    autoFocus?: boolean;
    isValid?: boolean;
    isAlpha?: boolean;
    height?: string;
    allowFloatValue?: boolean;
    customStyles?: CustomStyles<StylesClasses>;
    endAdornment?: any;
    startAdornment?: any;
    showErrorAdornment?: boolean;
    view?: boolean;
    multiline?: boolean;
    maxRows?: number;
    charCount?: boolean;
    totalCharCount?: number;
    InputLabelProps?: any;
    tooltipDesc?: string;
    InputProps?: any;
    showPrefix?: boolean;
    ariaLabel?: string;
  };

export const Input = <T extends FieldValues>({
  name,
  control,
  label,
  type = "text",
  fullWidth = true,
  labelPos = false,
  labelPosStyles = false,
  disabled = false,
  rules,
  maxLength,
  inputProps,
  placeholder,
  isAlpha = false,
  showAdornment = false,
  adornmentPos = "end",
  variant = "standard",
  className,
  isValid = false,
  readOnly = false,
  autoFocus = false,
  height = "40px",
  allowFloatValue = true,
  customStyles,
  endAdornment,
  startAdornment,
  onKeyDown,
  showErrorAdornment = false,
  view = false,
  InputLabelProps,
  charCount = false,
  totalCharCount = 250,
  multiline = false,
  tooltipDesc,
  InputProps,
  showPrefix = false,
  ariaLabel,
  ...rest
}: InputProps<T>) => {
  const styles = getStyles<StylesClasses>(defaultStyles, customStyles);
  const handleKeyDown = (event) => {
    if (type === "number" && !allowFloatValue) {
      if (
        type === "number" &&
        event.keyCode !== 8 &&
        (event.key === "e" || event.key === "E" || !/^\d$/.test(event.key))
      ) {
        event.preventDefault();
      }
    }
    if (type === "number" && allowFloatValue) {
      if (
        type === "number" &&
        event.keyCode !== 8 &&
        (event.key === "e" || event.key === "E")
      ) {
        event.preventDefault();
      }
    }
  };

  const [{ width }] = useWindowDimensions();

  return (
    <Controller
      render={({ field, fieldState }) => (
        <Box
          {...styles("wrapper", {
            color: readOnly ? "custom.text.secondary" : "initial",
            // mt: "20px",
          })}
        >
          {view ? (
            <ViewInput
              label={label}
              value={field.value}
              showPrefix={showPrefix}
            />
          ) : (
            <>
              <TextField
                id={name}
                required={!!rules?.required}
                variant={variant}
                label={label}
                autoComplete="off"
                {...styles(["input"], {
                  color: readOnly ? "custom.text.secondary" : "initial",
                  height: { height },
                })}
                type={type} // number/text - text
                value={field?.value}
                disabled={disabled}
                onKeyDown={handleKeyDown}
                onChange={(e) => {
                  let value = e.target.value;
                  if (type === "number") {
                    value = value.replace(/[^0-9.]/g, ""); // Remove non-numeric characters
                    if (allowFloatValue) {
                      const parts = value.split(".");
                      if (parts.length > 1) {
                        value = `${parts[0]}.${parts[1].slice(0, 2)}`;
                      }
                    } else {
                      // value = value.replace(/[^0-9.]/g, "");
                      value = value.replace(/\D/g, "");
                    }
                  }

                  const newValue =
                    maxLength && type === "number"
                      ? value.replace(/[^0-9]/g, "").slice(0, 4)
                      : value;

                  field.onChange(newValue);
                }}
                inputRef={field.ref}
                multiline={multiline}
                onBlur={field.onBlur}
                onFocus={(e) =>
                  e.target.addEventListener(
                    "wheel",
                    function (e) {
                      e.preventDefault();
                    },
                    { passive: false },
                  )
                }
                placeholder={placeholder}
                autoFocus={autoFocus}
                InputLabelProps={InputLabelProps}
                inputProps={{
                  maxLength: maxLength,
                  "aria-label": rest["aria-label"] ? rest["aria-label"] : name,
                  ...inputProps,
                }}
                InputProps={{
                  endAdornment:
                    fieldState.error && showErrorAdornment ? (
                      <InputAdornment position="end">
                        <Box
                          component="img"
                          src="/assets/svg/fieldErrorIcon.svg"
                          alt="icon"
                        />
                      </InputAdornment>
                    ) : (
                      <InputAdornment position="end">
                        {endAdornment}
                      </InputAdornment>
                    ),
                  startAdornment: !!startAdornment && (
                    <InputAdornment position="end">
                      {startAdornment}
                    </InputAdornment>
                  ),
                  ...InputProps,
                }}
                error={!!fieldState.error}
                {...rest}
              />
              {tooltipDesc && (
                <Box
                  sx={{
                    position: "absolute",
                    right: "15px",
                    top: "-10px",
                  }}
                >
                  <ToolTip
                    description={tooltipDesc}
                    customStyles={{
                      iconWrapper: {
                        zIndex: 999,
                        backgroundColor: "custom.text.white.100",
                        color: "black",
                      },
                    }}
                    placement={width > 1200 ? "top" : "top-end"}
                  />
                </Box>
              )}
              {fieldState.error && (
                <FormHelperText
                  {...styles("errorMsg", {
                    bottom: variant === "outlined" ? "-36px" : "-36px",
                  })}
                >
                  {fieldState.error.message}
                </FormHelperText>
              )}
              {charCount && (
                <FormHelperText
                  {...styles([fieldState.error ? "countError" : "count"], {
                    color: fieldState.error ? "error.main" : "custom.greyish.1",
                  })}
                >
                  {field?.value?.length}/{totalCharCount}
                </FormHelperText>
              )}
            </>
          )}
        </Box>
      )}
      name={name}
      control={control}
      rules={rules}
      {...rest}
    />
  );
};
