import { createAsyncThunk } from "@reduxjs/toolkit";
import { getViewInfo } from "src/member/utils/api/profile";

// Member Profile
export const getMemberProfile = createAsyncThunk(
  "getMemberProfile",
  async (id: string) => {
    return getViewInfo(id);
  },
);
