import { Box } from "@mui/material";
import { getStyles } from "src/shared/styles/theme";
import defaultStyles, { StylesClasses } from "./styles";
import { useNavigate } from "react-router-dom";
import useWindowDimensions from "src/shared/hook/useWindowDimensions";
import BarterIconButton from "../BarterIconButton";

const LogoHeader = () => {
  const styles = getStyles<StylesClasses>(defaultStyles);
  const navigate = useNavigate();
  const [{ width }] = useWindowDimensions();
  let sourceImage;
  if (width < 600) {
    sourceImage = "/assets/images/barterLogoSm.png";
  } else if (width < 1200) {
    sourceImage = "/assets/images/barterLogo720.png";
  } else {
    sourceImage = "/assets/images/barteritLogo.png";
  }
  return (
    <BarterIconButton onClick={() => navigate("/")}>
      <Box {...styles("wrapper")}>
        <Box component="img" src={sourceImage} alt="Logo" {...styles("logo")} />
      </Box>
    </BarterIconButton>
  );
};

export default LogoHeader;
