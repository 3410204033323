import { createStyles } from "src/shared/styles/theme";

export type StylesClasses = keyof typeof styles;

const styles = createStyles({
  titleBoxContainer: {},
  title: {
    color: "custom.text.toolTipTitle",
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: "600",
  },
  description: {
    color: "custom.text.toolTipDesc",
    fontSize: "16px",
    fontWeight: "400",
    letterSpacing: "0.28px",
    textAlign: "center",
  },
  iconWrapper: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "primary.main",
    color: "custom.text.white.100",
    height: "20px",
    width: "20px",
    padding: "10px",
    borderRadius: "50%",
    border: "2px solid black",
    transition: "0.3s ease-in-out",
    "&:hover": {
      backgroundColor: "custom.text.white.100",
      color: "primary.main",
    },
  },
  trademark: {
    fontWeight: "500",
    fontSize: { lg: "12px", sm: "10px", xs: "10px" },
  },
  children: {},
});

export default styles;
