import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { BusinessDetailsFormData } from "src/member/components/feature/Profile/Business";
import { clearSignUpForm } from "../../createActions/clearSignupForm";
import { logoutUser } from "src/redux/actions";

type BusinessInfo = { formData: BusinessDetailsFormData };

const initialState: BusinessInfo = {
  formData: {
    businessName: "",
    website: "",
    phoneNumber: "",
    countryCode: "+1",
    address: {
      province: "",
      city: "",
      country: "Canada",
      addressLine1: "",
      apartment: "",
      postalCode: "",
    },
  },
};

const BusinessInfoSlice = createSlice({
  name: "businessInfo",
  initialState,
  reducers: {
    setBusinessInfo: (
      state,
      action: PayloadAction<BusinessDetailsFormData>,
    ) => {
      state.formData = action.payload;
    },
    clearBusinessInfo: (state) => {
      state.formData = initialState.formData;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(clearSignUpForm, (state, _action) => {
      state.formData = initialState.formData;
    });
    builder.addCase(logoutUser, (state, _action) => {
      state.formData = initialState.formData;
    });
  },
});

export const { setBusinessInfo, clearBusinessInfo } = BusinessInfoSlice.actions;
export default BusinessInfoSlice.reducer;
