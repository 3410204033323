import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  adminProfileView,
  appLogoutApi,
} from "src/admin/utils/api/admin-private";

export const getAdminProfile = createAsyncThunk(
  "getUserProfile",
  adminProfileView,
);

export const appLogout = createAsyncThunk("appLogout", appLogoutApi);
