import { PayloadAction, createSlice } from "@reduxjs/toolkit";

import { AdminProfile, AdminRole } from "src/admin/types/api";
import { getAdminProfile } from "src/redux/thunks";
import { adminDetailsInitials } from "../initials";

type CurrentRoute = {
  currentPage: string;
};

const initialState: CurrentRoute = {
  currentPage: "home",
};
const currentRoute = createSlice({
  name: "user",
  initialState,
  reducers: {
    setCurrentPage: (state, action: PayloadAction<CurrentRoute>) => {
      state.currentPage = action.payload.currentPage;
    },
  },
});

export const { setCurrentPage } = currentRoute.actions;
export default currentRoute.reducer;
