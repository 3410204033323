import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { AUTH_METHODS } from "src/member/constants/enums";
import { clearSignUpForm } from "../../createActions/clearSignupForm";
import { logoutUser } from "src/redux/actions";

type SocialSignup = {
  type: AUTH_METHODS;
  userId: string;
  checkOutStatus: string;
  onboardingToken?: string;
};

type Custom = {
  email: string;
  password: string;
};

type Option = {
  name: string;
  id: string;
  categoryId?: string;
};

type BusinessCategories = Record<string, Option[]>;

type Signup = {
  social: SocialSignup;
  custom: Custom;
  businessCategories: BusinessCategories;
};

const initialState: Signup = {
  social: {
    type: AUTH_METHODS.CUSTOM,
    userId: "",
    checkOutStatus: "",
    onboardingToken: "",
  },
  custom: { email: "", password: "" },
  businessCategories: {
    products: [],
  },
};

const signUpDetails = createSlice({
  name: "signUpDetails",
  initialState,
  reducers: {
    setSignUpData: (state, action: PayloadAction<SocialSignup>) => {
      state.social.type = action.payload.type;
      state.social.userId = action.payload.userId;
      state.social.checkOutStatus = action.payload.checkOutStatus;
      state.social.onboardingToken = action.payload.onboardingToken ?? "";
    },
    customSignUp: (state, action: PayloadAction<{ email: string }>) => {
      state.custom.email = action.payload.email;
      state.social.type = AUTH_METHODS.CUSTOM;
    },
    setPassword: (state, action: PayloadAction<{ password: string }>) => {
      state.custom.password = action.payload.password;
    },
    setOnBoardingToken: (state, action: PayloadAction<{ token: string }>) => {
      state.social.onboardingToken = action.payload.token;
    },
    setBusinessCategories: (
      state,
      action: PayloadAction<BusinessCategories>,
    ) => {
      state.businessCategories = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(clearSignUpForm, (state, _action) => {
      state.social = initialState.social;
      state.custom = initialState.custom;
    });
    builder.addCase(logoutUser, (state, _action) => {
      state.social = initialState.social;
      state.custom = initialState.custom;
    });
  },
});

export const {
  setSignUpData,
  customSignUp,
  setPassword,
  setOnBoardingToken,
  setBusinessCategories,
} = signUpDetails.actions;

export default signUpDetails.reducer;
