import { PayloadAction, createSlice } from "@reduxjs/toolkit";

type initialStateType = {
  token: string;
};

const initialState: initialStateType = {
  token: "",
};
const xToken = createSlice({
  name: "adminProfileSetup",
  initialState,
  reducers: {
    setXToken: (state, action: PayloadAction<string>) => {
      state.token = action.payload;
    },
  },
});

export const { setXToken } = xToken.actions;
export default xToken.reducer;
