import { Box, ClickAwayListener, Tooltip as MuiTooltip } from "@mui/material";
import { ReactNode, useState } from "react";
import { getText } from "src/member/layout/Profile";
import { CustomStyles, getStyles } from "src/shared/styles/theme";
import defaultStyles, { StylesClasses } from "./styles";

type TooltipProps = {
  customStyles?: CustomStyles<StylesClasses>;
  title?: string;
  description?: string;
  classes?: "error" | "default";
  children?: React.ReactNode;
  arrow?: boolean;
  placement?: "top" | "bottom" | "left" | "right" | "top-end" | "bottom-end";
  isCustomDescription?: boolean;
  customDescription?: ReactNode;
};

export const ToolTip = ({
  customStyles,
  classes = "default",
  children,
  title,
  arrow = true,
  description,
  isCustomDescription = false,
  placement = "top",
  customDescription,
}: // defaultView = true,
TooltipProps) => {
  const styles = getStyles<StylesClasses>(defaultStyles, customStyles);

  const [open, setOpen] = useState(false);

  const handleTooltipClose = () => {
    setOpen(false);
  };

  const handleTooltipOpen = () => {
    setOpen(!open);
  };

  return (
    <ClickAwayListener onClickAway={handleTooltipClose}>
      <MuiTooltip
        classes={{ tooltipArrow: classes, arrow: classes }}
        // onClose={handleTooltipClose}
        open={open}
        disableFocusListener
        disableHoverListener
        onClick={handleTooltipOpen}
        // disableTouchListener
        title={
          <Box {...styles("titleBoxContainer")}>
            {title && <Box {...styles("title")}>{title}</Box>}
            {description && (
              <Box {...styles("description")}>
                {getText(description).length > 1 ? (
                  <Box {...styles("description")}>
                    {getText(description)[0]}
                    <Box component="span" {...styles("trademark")}>
                      <sup>{getText(description)[1]}</sup>
                    </Box>{" "}
                    {getText(description)[2]}
                  </Box>
                ) : isCustomDescription ? (
                  <Box {...styles("description")}>{customDescription}</Box>
                ) : (
                  <Box {...styles("description")}>{description}</Box>
                )}
              </Box>
            )}
          </Box>
        }
        arrow={arrow}
        placement={placement}
      >
        {!!!children ? (
          <Box {...styles("iconWrapper")}>i</Box>
        ) : (
          <Box {...styles("children")}>{children}</Box>
        )}
      </MuiTooltip>
    </ClickAwayListener>
  );
};

export default ToolTip;
