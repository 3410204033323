const errorMessage = {
  required: "Required field",
  invalidEmail: "Invalid email format, please try again.",
  invalidContact: "Please enter a valid contact number.",
  unregisteredEMail: "Please enter a registered email address.",
  emailExist: "Email already exists.",
  pwdMinLength: "Minimum 8 characters required.",
  minLength: "Minimum 5 number should be required.",
  maxLength: "Maximum 8 number should be required.",
  phoneLength: "Mobile number has to be 10 digits",
  confirmPassword: "Password should be same.",
  phoneDigits: "Mobile number has to be 10 digits",
  textAreaLength: "Length should be less than 250.",
  cancelMembershipText: "Exceeding character limit of 250 characters.",
  validUrl: "Please enter a valid url.",
  weakPin: "Commonly used or easily guessable pin.",
  uniqueEmail: (email) =>
    `If an account associated with ${email} exists, an email will be sent to that account.`,
  digitRequired: (num: number) =>
    `This needs to be total of ${num} digit number.`,
  emailAlreadyExist:
    "This email address is already associated with an account.",
  emailDoesNotExist: "This email address is not associated with any account.",
  wrongSignInMethod:
    "This email address is already associated with an account. Please sign in using the same method you used to create this account.",
  userNameAlreadyExist:
    "This username is already in use. Please choose a different one.",
  mobilNumberAlreadyTaken:
    "This mobile number is already in use. Please choose a different one.",
  inValidUserName:
    "Username contains invalid characters. Please use only lower case letters, numbers, and underscores.",
  invalidUserNamePattern:
    "Username doesn't meet our criteria. Please use a combination of letters and numbers.",
  fileUpload: {
    requiredFiles: "Please attach the required files.",
    maxFileSize: (limit: number) => `Max Limit ${limit} MB.`,
    invalidFileType: "Invalid File type.",
    alreadyExist: "File already exist.",
    fileLimitExceed: (maxLimit: number) =>
      `Please attach only ${maxLimit} files.`,
    uploadLimitation: "Please upload one file at a time.",
  },
  unknownErr: "Some error occurred.",
  inValidCredentials: "Invalid credentials. Please try again.",
  invalidPassword: "Passwords do not match. Please try again.",
  password:
    "8 Characters | 1 Uppercase | 1 Lowercase | 1 Digit | 1 Special Character.",
  matchPassword: "Passwords do not match, please try again.",
  samePassword: "The new password must be different than the current one.",
  socialHandle: "Please enter a valid website URL.",
  inValidMobileNumber: "Mobile number has to be 10 digits.",
  invalidCreds:
    "The credentials you have entered are incorrect. Please try again.",
  mobileNumberLength:
    "Missing Digits: Mobile number is incomplete. Please provide all 10 digits.",
  validName: "Please enter a valid name.",
  locationRequired: "Please select a location.",
  nameValidation: "Only English alphabets are allowed",
  incorrectPin: "Incorrect PIN. Please try again.",
  notYoutubeLink: "Invalid link format. Non-Youtube links are not supported.",
  numberField: "Please enter numerical values (Required field)",
  enterNumerical: "Please enter the numerical values. For e.g.: 4.50 or 2",
  maximumNumber: "Please do not enter more than 1000000 ",
};

export default errorMessage;
