import { Box } from "@mui/material";
import { CustomStyles, getStyles } from "src/shared/styles/theme";
import defaultStyles, { StylesClasses } from "./styles";
type ViewInputProps = {
  label: string | React.ReactNode;
  value: string | number;
  customStyles?: CustomStyles<StylesClasses>;
  showPrefix?: boolean;
};

const ViewInput = ({
  label,
  value,
  customStyles,
  showPrefix,
}: ViewInputProps) => {
  const styles = getStyles<StylesClasses>(defaultStyles, customStyles);
  const line = !!value && typeof value === "string" ? value.split("\n") : null;
  return (
    <Box {...styles("wrapper")}>
      <Box {...styles("label")}>{label}</Box>
      {line ? (
        line.map((line, index) => (
          <Box {...styles("value")} key={index}>
            {showPrefix && <Box component="span">+1&nbsp;</Box>} {line}
            <br />
          </Box>
        ))
      ) : (
        <Box {...styles("value")}>{value ? value : "-"}</Box>
      )}
    </Box>
  );
};

export default ViewInput;
