import {
  Box,
  CircularProgress,
  Button as MuiButton,
  ButtonProps as MuiButtonProps,
} from "@mui/material";
import { CustomStyles, getStyles } from "src/shared/styles/theme";
import defaultStyle, { StylesClasses } from "./styles";

export type ButtonProps = MuiButtonProps & {
  text: string;
  loading?: boolean;
  variant?: "contained" | "outlined" | "text";
  customStyles?: CustomStyles<StylesClasses>;
  src?: string;
  type?: "button" | "reset" | "submit";
  loaderSize?: string;
  Icon?: any;
  iconSrc?: string;
  rightIconSrc?: string;
  areaLabel?: string;
  rightIconAlt?: string;
  iconSrcAlt?: string;
};

export const Button = ({
  text,
  onClick,
  loading,
  disabled,
  variant = "contained",
  type = "button",
  customStyles,
  src,
  endIcon,
  loaderSize = "1.5rem",
  iconSrc,
  rightIconSrc,
  areaLabel = "button",
  rightIconAlt = "Icon",
  iconSrcAlt = "Icon",
  ...rest
}: ButtonProps) => {
  const styles = getStyles<StylesClasses>(defaultStyle, customStyles);

  return (
    <MuiButton
      {...styles(["root", `${variant}`])}
      color="primary"
      variant={variant}
      onClick={onClick}
      disabled={disabled || loading}
      type={type}
      aria-label={areaLabel}
      {...rest}
      endIcon={
        loading ? (
          <CircularProgress
            {...styles("circle")}
            size={loaderSize}
            color="secondary"
          />
        ) : (
          endIcon
        )
      }
      startIcon={rest.startIcon}
    >
      {iconSrc && (
        <Box
          component="img"
          src={iconSrc}
          {...styles("iconSrc")}
          alt={iconSrcAlt}
        />
      )}

      <Box {...styles("btnText")}>{text}</Box>
      {rightIconSrc && (
        <Box component="img" src={rightIconSrc} alt={rightIconAlt} />
      )}
    </MuiButton>
  );
};
