import { IconButton, IconButtonProps } from "@mui/material";
import React from "react";
import { CustomStyles, getStyles } from "src/shared/styles/theme";
import defaultStyles, { StylesClasses } from "./styles";

type BarterIconButtonProps = {
  customStyles?: CustomStyles<StylesClasses>;
  outlineColor?: string;
} & IconButtonProps;

const BarterIconButton = ({
  children,
  customStyles,
  outlineColor = "#0f0f0f",
  ...rest
}: BarterIconButtonProps) => {
  const styles = getStyles<StylesClasses>(defaultStyles, customStyles);
  return (
    <IconButton
      {...rest}
      disableRipple
      {...styles("root", {
        "&:focus-visible": {
          outline: "0.5px",
          outlineStyle: "solid",
          outlineColor: outlineColor,
          padding: "0px",
        },
      })}
    >
      {children}
    </IconButton>
  );
};

export default BarterIconButton;
