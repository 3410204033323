import { createStyles } from "src/shared/styles/theme";

export type StylesClasses = keyof typeof styles;

const styles = createStyles({
  wrapper: {
    position: "relative",
    width: "100%",
    "& .paper": {
      inset: "10px auto auto 0",
      marginTop: "20px",
    },
  },

  paper: {
    marginTop: "16px",
    width: "100%",
  },

  menuOptionIcon: {
    fontSize: "24px",
  },

  input: {
    // height: "52px",

    color: "red",

    "&.Mui-disabled": {
      WebkitTextFillColor: "#000",
      color: "red",
    },

    "& .MuiInputLabel-root": {
      fontSize: "16px",
      fontWeight: 400,
    },

    flexGrow: 1,
    "& ..css-108a452-MuiInputBase-root": {
      mt: 0,
    },

    "& .MuiInputBase-input": {
      "& .MuiFormLabel-root-MuiInputLabel-root": {
        lineHeight: 1,
        backgroundColor: "red",
      },
    },

    "& .MuiOutlinedInput-root": {
      MozAppearance: "textfield",
      padding: "8px 16px",
      paddingRight: "16px !important",

      "&::-webkit-outer-spin-button, &::-webkit-inner-spin-button": {
        WebkitAppearance: "none",
        MozAppearance: "textfield",
        "&::-moz-focus-inner": {
          borderStyle: "none",
        },
      },
    },
  },

  label: {},

  inputLabel: {},

  errormsg: {
    mb: "5px",
    bottom: "-42px",
  },
});

export default styles;
