import { Box, FormControlLabel } from "@mui/material";
import Radio from "@mui/material/Radio";
import React from "react";
import defaultStyles, { StylesClasses } from "./styles";
import { CustomStyles, getStyles } from "src/shared/styles/theme";

export type RadioProps = {
  handleChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  checked: boolean;
  value: string;
  label?: string;
  customStyles?: CustomStyles<StylesClasses>;
  description?: string;
};
const RadioInput = ({
  checked,
  handleChange,
  value,
  label,
  customStyles,
  description,
}: RadioProps) => {
  const styles = getStyles<StylesClasses>(defaultStyles, customStyles);

  return (
    <FormControlLabel
      {...styles("container")}
      value={value}
      control={
        <Radio
          checked={checked}
          id="label"
          onChange={handleChange}
          value={value}
          name="radio-buttons"
          inputProps={{ "aria-label": "B" }}
          {...styles("wrapper")}
          disableRipple={false}
        />
      }
      label={
        <Box {...styles("details")}>
          <Box {...styles("label")}>{label}</Box>
          {description && <Box {...styles("description")}>{description}</Box>}
        </Box>
      }
    />
  );
};

export default RadioInput;
