import { Box } from "@mui/material";
import { getStyles } from "src/shared/styles/theme";
import defaultStyles, { StylesClasses } from "./styles";
import { Button } from "../../common/FormComponents";
import { useNavigate } from "react-router-dom";
import { memberPath } from "src/member/constants/path/path";

const NotFound = () => {
  const styles = getStyles<StylesClasses>(defaultStyles);
  const navigate = useNavigate();
  return (
    <Box {...styles("wrapper")}>
      <Box {...styles("barteritLogo")}>
        <Box
          component="img"
          src="/assets/svg/barter-logo.svg"
          alt="barter-icon"
          onClick={() => navigate("/")}
          {...styles("logo")}
        />
      </Box>

      <Box {...styles("errorWrapper")}>
        <Box {...styles("error")}>404</Box>
        <Box {...styles("pageNotFound")}>
          Like a polar bear in a snowstorm, we can't find this page.
        </Box>
        <Button
          text="Home"
          onClick={() => navigate(memberPath.home)}
          customStyles={{
            root: {
              width: "160px",
              zIndex: 999,
            },
          }}
        />
      </Box>
    </Box>
  );
};

export default NotFound;
