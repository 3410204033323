import { PayloadAction, createSlice } from "@reduxjs/toolkit";

type InitialState = {
  routes: string[];
  isEnableRoute: boolean;
  adminNote: string;
  xSystemUnlockToken: string;
  isModulesLocked: boolean;
  isSystemDown: boolean;
};

const initialState: InitialState = {
  routes: [],
  isEnableRoute: false,
  adminNote: "",
  xSystemUnlockToken: "",
  isModulesLocked: false,
  isSystemDown: false,
};
const restrictedRoutesSlice = createSlice({
  name: "restrictedRoutesSlice",
  initialState,
  reducers: {
    setRestrictions: (
      state,
      action: PayloadAction<{
        routes: string[];
        adminNote: string;
        xSystemUnlockToken?: string;
        isSystemDown?: boolean;
        isModulesLocked?: boolean;
      }>,
    ) => {
      state.routes = action.payload.routes;
      state.isEnableRoute = true;
      state.adminNote = action.payload.adminNote;
      state.xSystemUnlockToken = action.payload.xSystemUnlockToken ?? "";
      state.isSystemDown = action.payload.isSystemDown;
      state.isModulesLocked = action.payload.isModulesLocked;
    },
  },
});

export const { setRestrictions } = restrictedRoutesSlice.actions;
export default restrictedRoutesSlice.reducer;
