import { createStyles } from "src/shared/styles/theme";

export type StylesClasses = keyof typeof styles;

const styles = createStyles({
  wrapper: {
    mt: "40px",
  },
  input: {},
  label: {
    fontSize: "22px",
    fontWeight: "500",
    marginBottom: "20px",
  },

  selectInputWrapper: {},

  // selectInputWrapper: {
  //   display: "flex",
  //   gap: "40px 3%",
  //   flexDirection: { xs: "column", lg: "row" },
  // },

  commonWrapper: {
    position: "relative",
    borderRadius: "4px",
    borderWidth: "1px",
    borderStyle: "solid",
    borderColor: "#7d7d7d3D",
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
    marginBottom: "10px",
    lineHeight: 0,
    padding: "5px",
    minHeight: "56px",
    gap: {
      lg: "0.5%",
      md: "0.4%",
      sm: "0.2%",
    },
  },
  imgBackground: {},
  cardWrapper: {
    background: "custom.text.white.100",
  },
  selectedCardWrapper: {
    borderRadius: "4px",
    border: "1.2px solid #0F0F0F",
    background: "#F1F1F1",
  },

  title: {
    fontSize: { lg: "16px", sm: "14px", xs: "14px" },
    fontWeight: "700",
    color: "primary.main",
    marginLeft: "10px",
    lineHeight: "normal",
  },
  description: {
    fontSize: "14px",
    color: "custom.greyish.4",
  },
  checked: {
    position: "absolute",
    right: "5px",
    top: "5px",
  },
});

export default styles;
