import { createStyles } from "src/shared/styles/theme";

export type StylesClasses = keyof typeof styles;

const styles = createStyles({
  wrapper: {
    width: "100%",
    position: "relative",
  },

  adornment: {
    position: "relative",
    top: "-10px",
  },

  input: {
    width: "100%",

    flexGrow: 1,
    "& ..css-108a452-MuiInputBase-root": {
      mt: 0,
    },

    "& .MuiInputLabel-root": {
      whiteSpace: "normal",
      overflow: "hidden",
      textOverflow: "ellipsis",
      maxWidth: "calc(100% - 0px)",
    },

    "& .MuiInputBase-input": {
      "& .MuiFormLabel-root-MuiInputLabel-root": {
        paddingInline: "16px",
      },

      MozAppearance: "textfield",
      "&::-webkit-outer-spin-button, &::-webkit-inner-spin-button": {
        WebkitAppearance: "none",
        MozAppearance: "textfield",
        "&::-moz-focus-inner": {
          borderStyle: "none",
        },
      },
    },
  },

  errorMsg: {
    whiteSpace: "wrap",
    paddingLeft: "16px",
    lineHeight: 1.2,
    bottom: "none",
    top: "54px",
  },
  count: {
    right: 0,
    bottom: "-32px",
    color: "#616161",
  },
  countError: {
    right: 0,
    bottom: "-32px",
  },

  doneIcon: {
    color: "custom.misc.green",
  },
});

export default styles;
