import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { MemberTypeFormData } from "src/member/components/feature/SetUpProfile";
import { clearSignUpForm } from "../../createActions/clearSignupForm";
import { logoutUser } from "src/redux/actions";

type MemberType = {
  formData: MemberTypeFormData;
};

const initialState: MemberType = {
  formData: {
    memberType: "",
  },
};

const memberTypeSlice = createSlice({
  name: "memberType",
  initialState,
  reducers: {
    setMemberType: (state, action: PayloadAction<MemberTypeFormData>) => {
      state.formData = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(clearSignUpForm, (state, _action) => {
      state.formData = initialState.formData;
    });
    builder.addCase(logoutUser, (state, _action) => {
      state.formData = initialState.formData;
    });
  },
});

export const { setMemberType } = memberTypeSlice.actions;
export default memberTypeSlice.reducer;
