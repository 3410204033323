import persistReducer from "redux-persist/es/persistReducer";
import storage from "redux-persist/lib/storage";
import adminProfileSetup from "./slices/adminProfileSetup";
import Xtoken from "./slices/Xtoken";
import { CombinedState, StateFromReducersMapObject } from "@reduxjs/toolkit";
import { TypedUseSelectorHook, useSelector } from "react-redux";
import adminDetails from "./slices/adminDetails";
import currentRoute from "./slices/currentRoute";
import notificationCount from "./slices/notificationCount";

export const adminProfileSetupConfig = {
  key: "adminProfileSetup",
  storage: storage,
};
export const XtokenConfig = {
  key: "Xtoken",
  storage: storage,
};
export const notificationCountConfig = {
  key: "notificationCount",
  storage: storage,
};
export const adminDetailsConfig = {
  key: "adminDetails",
  storage: storage,
};
export const currentRouteConfig = {
  key: "currentRoute",
  storage: storage,
};

export const reducers = {
  adminSampleDetails: persistReducer(
    adminProfileSetupConfig,
    adminProfileSetup,
  ),
  xtoken: persistReducer(XtokenConfig, Xtoken),
  notificationCount: persistReducer(notificationCountConfig, notificationCount),
  admin: persistReducer(adminDetailsConfig, adminDetails),
  currentRoute: persistReducer(currentRouteConfig, currentRoute),
};

type AdminRootState = {
  root: CombinedState<StateFromReducersMapObject<typeof reducers>>;
};

export const useAdminAppSelector: TypedUseSelectorHook<AdminRootState> =
  useSelector;
