import { PayloadAction, createSlice } from "@reduxjs/toolkit";

type ProfileType = {
  firstName: string;
  lastName: string;
  mobileNo: string;
};

type initialStateType = {
  profile: ProfileType;
};

const initialState: initialStateType = {
  profile: { firstName: "", lastName: "", mobileNo: "" },
};
const adminProfileSetup = createSlice({
  name: "adminProfileSetup",
  initialState,
  reducers: {
    setAdminProfileDetails: (state, action: PayloadAction<ProfileType>) => {
      state.profile = action.payload;
    },
  },
});

export const { setAdminProfileDetails } = adminProfileSetup.actions;
export default adminProfileSetup.reducer;
