import { createStyles } from "src/shared/styles/theme";

export type StylesClasses = keyof typeof styles;

const styles = createStyles({
  wrapper: { display: "flex", flexDirection: "column" },
  titleWrapper: {
    padding: "50px 0px",
    backgroundColor: "primary.main",
    textAlign: "center",
    color: "white",
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    position: "relative",
  },
  leftArrow: {
    position: "absolute",
    left: {
      lg: "80px",
      sm: "50px",
      xs: "30px",
    },
    cursor: "pointer",
  },
  logo: {
    paddingLeft: {
      lg: "87px",
      sm: "50px",
      xs: "30px",
    },
    paddingTop: "16px",
  },
  title: {
    typography: "leagueSpartan.medium",
    width: {
      lg: "80%",
      sm: "42%",
      xs: "70%",
    },
    fontSize: {
      lg: "28px",
      sm: "24px",
      xs: "20px",
    },
    lineHeight: "28px",
  },
  trademark: {
    fontSize: { lg: "22px", sm: "20px", xs: "18px" },
  },
});

export default styles;
