import { createStyles } from "src/shared/styles/theme";

export type StylesClasses = keyof typeof styles;

const styles = createStyles({
  container: { margin: "10px 0px" },

  label: {
    position: "static",
    marginTop: "5px",
  },

  outerLayer: {
    display: "flex",
    flexDirection: "column-reverse",
  },
  dragText: {},

  skeletonWrapper: {
    maxWidth: "100%",
    marginTop: "10px",
  },

  borderStyle: {},

  wrapper: {
    border: "2px dashed",
    borderRadius: "12px",
    height: "fit-content",
    backgroundColor: "custom.background.secondaryGrey",
    display: "flex",
    margin: "10px 0px",
    paddingBottom: "5px",
    width: "100%",
    "& .inputButton": {
      marginTop: "20%",
      marginLeft: "37%",
      backgroundColor: "custom.background.secondaryGrey",
      width: "20%",
      height: "15%",
      position: "absolute",
      opacity: 0,
    },
  },

  inputWrapper: {
    position: "absolute",
    display: "flex",
    alignItems: "center",
    inset: "0 0 0 0",
    opacity: 0,
    flexDirection: "column-reverse",
  },

  content: {
    display: "flex",
    flexDirection: "column",
    gap: "10px",
    color: "custom.heading",
    marginTop: "10px",
    fontSize: "14px",
  },

  contentStandard: {
    display: "flex",
    alignItems: "center",
    color: "custom.heading",
    fontSize: "14px",
    margin: { xs: "5px auto 0", sm: "10px auto 0" },
    textAlign: "center",
    gap: {
      xs: "40px",
      sm: "20px",
    },
  },

  uploadImage: {
    width: {
      xs: "60px",
      sm: "125px",
    },
    height: {
      xs: "53px",
      sm: "112px",
    },
    marginLeft: "20px",

    img: {
      width: "100%",
      height: "100%",
    },
  },

  dragAndDropArea: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    margin: "0 5px",
    padding: "20px",
    fontSize: {
      xs: "12px",
      sm: "14px",
    },
  },

  dragDropHeading: {
    // display: { xs: "block", sm: "flex" },
    fontWeight: 400,
  },

  dragArea: {
    display: "flex",
    // whiteSpace: "nowrap",
    fontWeight: "600",
    flexDirection: {
      xs: "column",
      sm: "row",
    },
    fontSize: "16px",
    gap: "5px",
  },

  uploadFile: {
    display: "flex",
    alignItems: "center",
    gap: "10px",
  },

  fileType: {
    color: "custom.label",
    margin: "10px 0",
    fontSize: {
      xs: "12px",
      sm: "13px",
    },
    fontWeight: 400,
  },

  btn: {
    borderRadius: "4px",
    border: "solid 2px",
    borderColor: "custom.heading",
    color: "custom.heading",
    fontWeight: 600,
    marginTop: {
      xs: "5px",
      sm: "10px",
    },

    height: {
      xs: "30px",
      sm: "40px",
    },
    fontSize: {
      xs: "9px",
      sm: "14px",
    },
  },

  fileUploadPreview: {
    padding: "14px 21px",
    display: "flex",
    justifyContent: "space-between",
    border: "2px solid",
    height: "fit-content",
    backgroundColor: "custom.border.background",
    borderColor: "custom.border.FileUploadBorder",
    borderRadius: "8px",
    margin: "9px 0px",
    columnGap: {
      xs: "5px",
      sm: "13px",
    },
    textDecoration: "none",
  },

  tickIcon: {
    display: "flex",
    justifyContent: "center",
    width: "130px",
    gap: "25%",
    alignItems: "center",
  },

  crossIcon: {
    zIndex: "0",
    cursor: "pointer",
  },

  imageBox: {
    gap: "10%",
    display: "flex",
  },

  overviewItemText: {
    display: "flex",
    alignItems: "center",
    gap: "10px",
  },

  receiptPreview: {
    width: {
      xs: "30px",
      sm: "100px",
    },
    height: {
      xs: "30px",
      sm: "56px",
    },
  },

  fileName: {
    flexGrow: 1,
    fontWeight: 500,
    fontSize: {
      xs: "14px",
      sm: "16px",
    },
    padding: {
      xs: "0px 10px",
      sm: "0px",
    },
    wordBreak: "break-word",
    color: "custom.text.light",
    textTransform: "capitalize",
    height: "20px",
    overflow: "hidden",

    "@media (max-width: 412px)": {
      wordBreak: "normal",
    },
  },

  errorMsg: {
    marginLeft: 0,
    bottom: "-23px",
  },

  uploaded: {
    // fontSize: "12px",
    fontWeight: 500,
    color: "#00B449",
  },

  or: {
    textAlign: "center",
    marginTop: "10px",
  },
});

export default styles;
