import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import {
  Autocomplete as MuiAutocomplete,
  Box,
  FormControlProps,
  FormHelperText,
  InputAdornment,
  InputBaseProps,
  Paper,
  TextField,
} from "@mui/material";
import { useState } from "react";
import {
  Controller,
  FieldErrors,
  FieldValues,
  Path,
  PathValue,
  UseControllerProps,
} from "react-hook-form";
import errorMessage from "src/shared/constants/errorMessage";
import { CustomStyles, getStyles } from "src/shared/styles/theme";
import { Option } from "src/shared/types/common";
import defaultStyles, { StylesClasses } from "./styles";
import ToolTip from "../../Tooltip";
import useWindowDimensions from "src/shared/hook/useWindowDimensions";

type AutoCompleteProps<T> = UseControllerProps<T> &
  FormControlProps &
  InputBaseProps & {
    paperClick?: boolean;
    label?: string;
    arialLabel?: string;
    errors?: FieldErrors;
    isLabel?: boolean;
    customStyles?: CustomStyles<StylesClasses>;
    freeSolo?: boolean;
    options?: Option[];
    loading?: boolean;
    searchLoading?: boolean;
    view?: boolean;
    disabled?: boolean;
    readOnly?: boolean;
    height?: string;
    valueObject?: boolean;
    type?: string;
    variant?: string;
    InputLabelProps?: any;
    showEndAdornment?: boolean;
    tooltipDesc?: string;
    sorted?: boolean;
    optionText?: {
      initial: string;
      filled: string;
    };
    onInputChange?: (
      e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
    ) => void;
    onOptionSelect?: (e: any) => void;
  };

type Value<T> = PathValue<T, Path<T>>;

const AutoComplete = <T extends FieldValues>({
  name,
  control,
  label,
  fullWidth = true,
  height = "40px",
  readOnly = false,
  errors,
  freeSolo = false,
  rules,
  options = [],
  customStyles,
  variant = "standard",
  placeholder,
  className,
  view = false,
  loading = false,
  searchLoading = false,
  disabled = false,
  onInputChange,
  InputLabelProps,
  type = "text",
  onOptionSelect,
  showEndAdornment = true,
  endAdornment,
  sorted = true,
  valueObject = false,
  startAdornment,
  tooltipDesc,
  isLabel = false,
  paperClick = false,
  arialLabel = "inputField",
  optionText = {
    initial: "",
    filled: "",
  },
  ...rest
}: AutoCompleteProps<T>) => {
  const styles = getStyles<StylesClasses>(defaultStyles, customStyles);

  const [showPaper, setShowPaper] =
    useState<keyof typeof optionText>("initial");

  const [isOpen, setIsOpen] = useState(false);

  const checkIfFilled = (inpVal: string) => {
    inpVal?.length ? setShowPaper("filled") : setShowPaper("initial");
  };

  const toggleDropdownIcon = (value: boolean) => {
    setIsOpen(value);
  };

  const handleKeyDown = (event) => {
    if (type === "number" && (event.key === "e" || event.key === "E")) {
      event.preventDefault();
    }
  };

  const [{ width }] = useWindowDimensions();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState }) => {
        if (
          typeof field.value === "string" ||
          typeof field.value === "number"
        ) {
          const optionValue = options.find(
            (item) => item.value === field?.value,
          ) as Value<T>;
          field.value = optionValue || field.value || ("" as Value<T>);
        }

        return (
          <Box {...styles("wrapper")}>
            {isLabel && <Box component="label">{label}</Box>}
            <MuiAutocomplete
              loading={searchLoading}
              disabled={disabled}
              readOnly={readOnly}
              onSelect={() => {}}
              options={
                sorted
                  ? [...options].sort((a, b) => {
                      return -b.label.localeCompare(a.label);
                    })
                  : options
              }
              open={isOpen}
              fullWidth={fullWidth}
              value={field.value}
              noOptionsText={
                optionText[showPaper] === ""
                  ? "no options"
                  : optionText[showPaper]
              }
              onChange={(_, newValue) => {
                if (!Array.isArray(newValue) && newValue !== null) {
                  typeof newValue === "string"
                    ? field.onChange(newValue)
                    : field.onChange(!valueObject ? newValue.value : newValue);
                } else field.onChange(newValue);

                onOptionSelect && onOptionSelect(newValue);
                toggleDropdownIcon(false);
              }}
              freeSolo={freeSolo}
              id={`input-${name}`}
              PaperComponent={(props) => (
                <Paper
                  elevation={8}
                  className="paper"
                  {...styles("paper")}
                  onClick={() => {
                    paperClick && toggleDropdownIcon(false);
                  }}
                  {...props}
                />
              )}
              renderInput={(params) => (
                <Box ref={params.InputProps.ref}>
                  <TextField
                    fullWidth={fullWidth}
                    required={!!rules?.required}
                    label={label}
                    onFocus={() => {
                      toggleDropdownIcon(true);
                    }}
                    variant={variant}
                    onBlur={(e) => {
                      field.onBlur();
                      toggleDropdownIcon(false);
                    }}
                    onKeyDown={handleKeyDown}
                    type={type}
                    placeholder={placeholder}
                    onClick={() =>
                      !!options?.length && toggleDropdownIcon(true)
                    }
                    InputLabelProps={InputLabelProps}
                    InputProps={{
                      ...(showEndAdornment && {
                        endAdornment: endAdornment || (
                          <InputAdornment position="end">
                            <KeyboardArrowDownIcon
                              {...styles("menuOptionIcon", {
                                transform:
                                  isOpen && !disabled
                                    ? "rotate(180deg)"
                                    : "rotate(0deg)",
                              })}
                            />
                          </InputAdornment>
                        ),
                      }),
                      ...(!!startAdornment && { startAdornment }),
                    }}
                    onChange={(e) => {
                      toggleDropdownIcon(true);
                      checkIfFilled(e.target.value);
                      onInputChange && onInputChange(e);
                      freeSolo && field.onChange(e.target.value);
                    }}
                    {...styles(["inputLabel", "input"], {
                      color: readOnly ? "custom.text.secondary" : "initial",
                      height: { height },
                    })}
                    error={!!fieldState?.error}
                    inputProps={{
                      ...params.inputProps,
                      form: {
                        autocomplete: "new-password",
                      },
                      "aria-label": name,
                    }}
                  />
                  {tooltipDesc && (
                    <Box
                      sx={{
                        position: "absolute",
                        right: "15px",
                        top: "-10px",
                      }}
                    >
                      <ToolTip
                        description={tooltipDesc}
                        customStyles={{
                          iconWrapper: {
                            zIndex: 999,
                            backgroundColor: "custom.text.white.100",
                            color: "black",
                          },
                        }}
                        placement={width > 1200 ? "top" : "top-end"}
                      />
                    </Box>
                  )}
                </Box>
              )}
            />
            {fieldState?.error?.message && !view && (
              <FormHelperText {...styles("errormsg")}>
                {fieldState.error.message}
              </FormHelperText>
            )}
          </Box>
        );
      }}
      rules={{
        ...rules,
        validate: {
          ...rules?.validate,
          isZero: (value) => value !== 0 || errorMessage.required,
        },
      }}
      {...rest}
    />
  );
};

export default AutoComplete;
