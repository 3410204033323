import { createStyles } from "src/shared/styles/theme";

export type StylesClasses = keyof typeof styles;

const styles = createStyles({
  wrapper: {
    /*  paddingLeft: {
      lg: "87px",
      sm: "50px",
      xs: "30px",
    }, */
    // paddingTop: "16px",
    width: "80px",
    // height: "69px",
  },
  logo: {
    width: "100%",
    height: "100%",
    cursor: "pointer",
    // objectFit: "cover",
  },
});

export default styles;
