import {
  Alert,
  AlertColor,
  Box,
  IconButton,
  Snackbar as MatSnackbar,
} from "@mui/material";
import { GridCloseIcon } from "@mui/x-data-grid";
import { ReactNode } from "react";
import defaultStyles, { StylesClasses } from "./styles";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { CustomStyles, getStyles } from "src/shared/styles/theme";
import useWindowDimensions from "src/shared/hook/useWindowDimensions";

type SnackbarProps = {
  message: string;
  open: boolean;
  severity: AlertColor;
  duration?: number;
  resetSnackbar: () => void;
  icon?: ReactNode;
  customStyles?: CustomStyles<StylesClasses>;
  isUndo?: boolean;
  onUndo?: () => void;
};

type IconProps = {
  severity: AlertColor;
};

const Icon = ({ severity }: IconProps) => {
  switch (severity) {
    case "success":
      return (
        <Box
          component="img"
          src="/assets/svg/circle-suucess.svg"
          alt="icon"
          width="24px"
          height="24px"
        />
      );
    case "error":
      return (
        <Box
          component="img"
          src="/assets/svg/deletePauseIcon.svg"
          alt="icon"
          width="24px"
          height="24px"
        />
      );

    default:
      return (
        <Box
          component="img"
          src="/assets/svg/deletePauseIcon.svg"
          alt="icon"
          width="24px"
          height="24px"
        />
      );
  }
};

const Snackbar = ({
  message,
  open,
  resetSnackbar,
  severity,
  duration = 3000,
  icon,
  customStyles,
  isUndo = false,
  onUndo,
}: SnackbarProps) => {
  const styles = getStyles<StylesClasses>(defaultStyles, customStyles);
  const { width } = useWindowDimensions()[0];

  return (
    <MatSnackbar
      open={open}
      autoHideDuration={duration}
      anchorOrigin={{
        vertical: "top",
        horizontal: width < 600 ? "center" : "right",
      }}
      onClose={resetSnackbar}
      {...styles("root")}
    >
      <Alert
        severity={severity}
        icon={<Icon severity={severity} />}
        {...styles("alertBox")}
      >
        <Box {...styles("messageWrapper")}>
          <Box component="span" {...styles("alert")}>
            {message}{" "}
            {isUndo && (
              <Box component="span" {...styles("undoMsg")} onClick={onUndo}>
                Undo
              </Box>
            )}
          </Box>
          <IconButton
            size="small"
            aria-label="close"
            color="inherit"
            onClick={() => {
              resetSnackbar();
              /*   onUndo && onUndo(); */
            }}
          >
            <GridCloseIcon fontSize="small" {...styles("crossIcon")} />
          </IconButton>
        </Box>
      </Alert>
    </MatSnackbar>
  );
};

export default Snackbar;
