import { createStyles } from "src/shared/styles/theme";

export type StylesClasses = keyof typeof styles;

const styles = createStyles({
  labelPos: {
    display: "flex",
    justifyContent: "start",
    alignItems: "center",
    "& .label": {
      fontSize: "14px",
      minWidth: "110px",
    },
  },

  labelPosStyles: {
    display: "flex",
    justifyContent: "start",
    alignItems: "center",
    "& .label": {
      minWidth: "0px",
      width: "0px",
    },
  },

  label: {
    position: "static",
  },

  error: {
    marginLeft: "110px",
  },

  doneIcon: {
    color: "custom.misc.green",
  },
});

export default styles;
