import { createStyles } from "src/shared/styles/theme";

export type StylesClasses = keyof typeof styles;

const styles = createStyles({
  root: {
    fontFamily: "Nunito Sans",
    fontSize: "16px",
    padding: "0px",
    lineHeight: "16px",
    cursor: "pointer",
    borderRadius: 0,
    color: "#0f0f0f",
  },
});

export default styles;
