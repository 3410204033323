import { createSlice } from "@reduxjs/toolkit";

import { AdminProfile, AdminRole } from "src/admin/types/api";
import { getAdminProfile } from "src/redux/thunks";
import { adminDetailsInitials } from "../initials";

export type AdminData = {
  adminProfile: AdminProfile;
  adminRole: AdminRole;
};

type Admin = {
  data: AdminData;
};

const initialState: Admin = {
  data: adminDetailsInitials,
};
const adminDetails = createSlice({
  name: "user",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getAdminProfile.fulfilled, (state, action) => {
      const responseData = action.payload.data;
      state.data = responseData;
    });
  },
});

// export const {} = adminDetails.actions;
export default adminDetails.reducer;
