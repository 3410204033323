import { createSlice } from "@reduxjs/toolkit";

type NavigationState = {
  isOpen: boolean;
};

const initialState: NavigationState = {
  isOpen: true,
};
const navigationState = createSlice({
  name: "navigationStateSlice",
  initialState,
  reducers: {
    expandNav: (state) => {
      state.isOpen = true;
    },
    collapseNav: (state) => {
      state.isOpen = false;
    },
  },
});

export const { expandNav, collapseNav } = navigationState.actions;
export default navigationState.reducer;
