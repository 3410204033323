import { createStyles } from "src/shared/styles/theme";

export type StylesClasses = keyof typeof styles;

const styles = createStyles({
  rootSelect: {
    backgroundColor: "transparent",
  },
  label: {},

  wrapper: {
    position: "relative",
    minWidth: "200px",
    "& .MuiInputBase-root": {
      "& .MuiSelect-select .MuiInputBase-input .MuiOutlinedInput-input": {
        padding: 0,
      },
    },
    "& .MuiInput-underline:after": {},
    "& .MuiOutlinedInput-root": {
      padding: 0,
      "& fieldset": {
        border: "none",
      },
      "&:hover fieldset": {
        border: "none",
      },
      "&.Mui-focused fieldset": {
        border: "none",
      },
    },
  },

  input: {},

  formHelperText: {
    bottom: "-24px",
  },

  chevronIcon: {
    top: "7px",
  },

  menuItems: {
    borderBottomWidth: "1px",
    borderBottomColor: "custom.border.lightSecondary",
    borderBottomStyle: "solid",
    margin: "0 10px",
    color: "custom.text.light",
  },
});

export default styles;
